import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type {
  AssignMembersToDeviceRequestBody,
  AssignMembersToDeviceResponseType,
  OrganizationId,
} from 'redux-store/slices/organizationOverview/types';

const assignMembersToDeviceThunk = createAsyncThunk(
  'organizationOverview/assignMembersToDevice',
  async ({
    organizationId,
    deviceId,
    data,
  }: {
    organizationId: OrganizationId;
    deviceId: number;
    data: AssignMembersToDeviceRequestBody;
  }): Promise<AssignMembersToDeviceResponseType> => {
    try {
      const apiPointName = 'assignMembersToDevice';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: data,
        urlParams: { organizationId, deviceId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default assignMembersToDeviceThunk;
