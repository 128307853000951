import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

const AllDevicesOutlinedIcon: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2458 5H20C20.5522 5 21 4.55228 21 4C21 3.44772 20.5522 3 20 3H17.099C16.7862 3 16.658 3.46167 16.9059 3.65239C17.41 4.04016 17.8609 4.49364 18.2458 5ZM21 18H18.9573C19.2658 17.3791 19.4867 16.7071 19.6038 16H21V8H19.5781C19.4454 7.29092 19.2081 6.61871 18.8828 6H21C22.1045 6 23 6.89543 23 8V16C23 17.1046 22.1045 18 21 18ZM20 21H17.261C16.9549 21 16.8253 20.5627 17.0626 20.3694C17.5506 19.9719 17.9851 19.5113 18.3538 19L20 19C20.5522 19 20.9999 19.4477 20.9999 20C21 20.5523 20.5522 21 20 21ZM14.4996 9.54545V11.366C15.3134 12.1719 15.8122 13.2587 15.8122 14.4545C15.8122 16.0981 14.8698 17.5359 13.4626 18.3213C13.1171 18.9225 12.6935 19.4787 12.2046 19.978C11.8628 20.3269 12.0932 20.9368 12.5708 20.8344C15.6805 20.1675 18 17.5643 18 14.4545V9.54545C18 6.43573 15.6805 3.8325 12.5708 3.16559C12.0932 3.06316 11.8629 3.67311 12.2047 4.02211C13.6299 5.47759 14.4996 7.41657 14.4996 9.54545ZM11.0999 9.24197V14.7581C11.0999 16.9948 9.28668 18.8081 7.04996 18.8081C4.81323 18.8081 3 16.9948 3 14.7581V9.24197C3 7.00524 4.81323 5.19202 7.04996 5.19202C9.28668 5.19202 11.0999 7.00524 11.0999 9.24197ZM1 9.24197C1 5.90067 3.70866 3.19202 7.04996 3.19202C10.3913 3.19202 13.0999 5.90068 13.0999 9.24197V14.7581C13.0999 18.0994 10.3913 20.8081 7.04996 20.8081C3.70866 20.8081 1 18.0994 1 14.7581V9.24197ZM10 15C10 16.6569 8.65685 18 7 18C5.34315 18 4 16.6569 4 15C4 13.3431 5.34315 12 7 12C8.65685 12 10 13.3431 10 15Z"
      fill={props.fill}
    />
  </SvgIcon>
);

export default AllDevicesOutlinedIcon;
