import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

const deleteOrganizationThunk = createAsyncThunk(
  'organizations/deleteOrganizationThunk',
  async (organizationId: number) => {
    try {
      const apiPointName = 'deleteOrganization';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        urlParams: { id: organizationId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default deleteOrganizationThunk;
