import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { HydrateAction } from 'redux-store';
import getAllOrganizationsThunk from 'redux-store/thunks/organizations/getAllOrganizationsThunk';
import getOrganizationByIdThunk from 'redux-store/thunks/organizations/getOrganizationByIdThunk';

import type { OrganizationsStateType } from './types';

const initialState = {
  isFetched: false,
  isFetching: false,
  items: [],
  selectedOrganization: {
    isFetched: false,
    isFetching: false,
    organization: null,
  },
} as OrganizationsStateType;

const OrganizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getOrganizationByIdThunk.fulfilled, (state, action) => {
      state.selectedOrganization.isFetched = true;
      state.selectedOrganization.isFetching = false;

      if (action.payload.success) {
        state.selectedOrganization.organization = action.payload.organization;
      }
    });

    builder.addCase(getAllOrganizationsThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      if (action.payload.success) {
        state.items = action.payload.organizations;

        if (state.selectedOrganization.organization) {
          const newSelectedOrganization = action.payload.organizations.find(
            organization =>
              organization.id === state.selectedOrganization.organization?.id,
          );

          if (newSelectedOrganization) {
            state.selectedOrganization.organization = newSelectedOrganization;
          }
        }
      }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<OrganizationsStateType>) => {
        return action.payload.organizations;
      },
    );
  },
});

export default OrganizationsSlice.reducer;
