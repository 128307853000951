import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Box } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import Modal from 'components/Modal';

import AddNewDevice from './AddNewDevice';
import AssignDevicesSuccess from './AssignDevicesSuccess';
import AddDevicesToMember from './AssignDevicesToMembers';

type AssignDevicesToMembers = 'devices' | 'addDevice' | 'success';

interface AssignDevicesToMembersModalState {
  stage: AssignDevicesToMembers;
  devices: number[];
  newDeviceID?: number;
}

export interface AssignDevicesToMembersModalStageProps {
  open: boolean;
  state: AssignDevicesToMembersModalState;
  setState: Dispatch<SetStateAction<AssignDevicesToMembersModalState>>;
  onClose: () => void;
  onCloseProxy: () => void;
  organizationId: number | null;
  userId: number | null;
  selectedDevices: number[];
}

const StageComponents: Record<
  AssignDevicesToMembers,
  FC<AssignDevicesToMembersModalStageProps>
> = {
  devices: AddDevicesToMember,
  addDevice: AddNewDevice,
  success: AssignDevicesSuccess,
};

interface Props extends DefaultPopupProps {
  organizationId: number | null;
  userId: number | null;
  selectedDevices: number[];
}

const AssignDevicesToMemberModal: FC<Props> = ({
  onClose,
  open,
  organizationId,
  userId,
  selectedDevices,
}) => {
  const [state, setState] = useState<AssignDevicesToMembersModalState>({
    stage: userId ? 'devices' : 'addDevice',
    devices: [],
  });

  useEffect(() => {
    if (!open) {
      setState(prev => ({ ...prev, email: '', role: '', devices: [] }));
    }
  }, [open]);

  const StageComponent = useMemo(() => StageComponents[state.stage], [state]);

  const onCloseProxy = useCallback((): void => {
    if (state.stage === 'devices' || !userId) {
      setState(prev => ({
        ...prev,
        devices: [],
        addDevice: '',
      }));

      onClose();
    } else if (state.stage === 'addDevice') {
      setState(prev => ({
        ...prev,
        stage: 'devices',
      }));
    }
  }, [onClose, state.stage, userId]);

  return (
    <Modal
      open={open}
      onClose={onCloseProxy}
      overrideCloseButtonIcon={
        state.stage !== 'devices' && userId !== null ? (
          <ArrowBackRoundedIcon />
        ) : undefined
      }
      hideCloseButton={state.stage === 'success'}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        sx={{ overflowY: 'auto' }}
      >
        <StageComponent
          open={open}
          setState={setState}
          state={state}
          onClose={onClose}
          onCloseProxy={onCloseProxy}
          organizationId={organizationId}
          userId={userId}
          selectedDevices={selectedDevices}
        />
      </Box>
    </Modal>
  );
};

export default AssignDevicesToMemberModal;
