import { Box, TextField, Typography } from '@mui/material';
import useRfidGroup from 'hooks/useRfidGroup';
import { useTranslation } from 'next-i18next';
import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import type { FC } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import SuccessIcon from 'components/Icons/SuccessIcon';
import Modal from 'components/Modal';

import { useAppDispatch } from 'redux-store';
import type { RfidGroupId } from 'redux-store/slices/rfidGroups/types';
import getAllRfidGroupsThunk from 'redux-store/thunks/rfidGroups/getAllRfidGroupsThunk';
import renameRfidGroupThunk from 'redux-store/thunks/rfidGroups/renameRfidGroupThunk';

export interface RenameRfidGroupModalProps extends DefaultPopupProps {
  groupId: RfidGroupId | null;
}

const RenameRfidGroupModal: FC<RenameRfidGroupModalProps> = ({
  groupId,
  onClose,
  open,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const groupNameInputRef = useRef<HTMLInputElement>(null);

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [inputError, setInputError] = useState<string | null>(null);

  const rfidGroup = useRfidGroup(groupId ?? undefined);

  const currentName = useMemo(() => rfidGroup?.name ?? '', [rfidGroup]);

  const [name, setName] = useState<string>(currentName);

  useEffect(() => {
    if (currentName) {
      setName(currentName);
    }
  }, [currentName]);

  const validateName = useCallback(
    (inputName: string): string => {
      if (!inputName) {
        return t(
          'common:pages.rfid_groups.add_rfid_group.errors.name_required',
        );
      }

      if (inputName.length > 50) {
        return t(
          'common:pages.rfid_groups.add_rfid_group.errors.group_name_max_length',
        );
      }

      return '';
    },
    [t],
  );

  const handleChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newName = e.target.value;
      const validationError = validateName(newName);
      setInputError(validationError);
      setName(newName);
    },
    [validateName],
  );

  const handleSave = useCallback(async () => {
    if (isLoading) return;
    if (groupId === null) return;

    const validationError = validateName(name);

    if (validationError) {
      setInputError(validationError);

      return;
    }

    setLoading(true);
    setError(null);

    try {
      const result = await dispatch(
        renameRfidGroupThunk({ rfidGroupId: groupId, data: { name } }),
      ).unwrap();

      if (result.success) {
        await dispatch(getAllRfidGroupsThunk({})).unwrap();

        setSuccess(true);

        setTimeout(() => {
          setSuccess(false);
          onClose();
        }, 1250);
      } else {
        setError(result.error);
      }
    } catch (err) {
      setError(err as string);
    } finally {
      setLoading(false);
    }
  }, [dispatch, groupId, isLoading, name, onClose, validateName]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        groupNameInputRef.current?.focus();
      }, 100);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      {!success ? (
        <Box
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ overflowY: 'auto' }}
        >
          <Box mb={4} width="100%">
            <Typography
              variant="h2"
              textAlign="center"
              mb={2}
              data-testid="rename-rfid-group-modal"
            >
              {t('common:pages.rfid_groups.rename_group.title')}
            </Typography>
            <Typography variant="body1" mb={2} textAlign="center">
              {t('common:pages.rfid_groups.rename_group.description')}
            </Typography>
          </Box>
          <Box mb={4} width="100%">
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSave();
              }}
            >
              <TextField
                variant="filled"
                fullWidth
                label={t('common:pages.rfid_groups.rename_group.group_name')}
                value={name}
                onChange={handleChangeName}
                inputProps={{ 'data-testid': 'rfid-group-new-name-input' }}
                error={Boolean(inputError)}
                helperText={inputError}
                inputRef={groupNameInputRef}
              />
            </form>
          </Box>
          <ErrorText error={error} mb={2} />
          <Box width="100%" display="flex" flexDirection="row" gap={2} pt={2}>
            <Button
              variant="flat"
              color="neutral"
              fullWidth
              size="large"
              onClick={onClose}
              data-testid="cancel-button"
            >
              {t('common:cancel')}
            </Button>
            <Button
              variant="flat"
              fullWidth
              size="large"
              onClick={handleSave}
              disabled={isLoading || Boolean(inputError)}
              data-testid="submit"
            >
              {t('common:save')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100%"
          sx={{ overflowY: 'auto' }}
        >
          <Box
            margin="50px 20px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <SuccessIcon width={100} height={100} />
            <Typography
              variant="h1"
              margin="20px 10px"
              textAlign="center"
              data-testid="success-text"
            >
              {t('common:pages.rfid_groups.rename_group.group_renamed')}
            </Typography>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default RenameRfidGroupModal;
