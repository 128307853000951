import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  Modal as MuiModal,
  styled,
  Typography,
} from '@mui/material';
import type { ReactNode, FC, ReactElement } from 'react';
import React from 'react';

export type ModalCloseReason = 'backdropClick' | 'escapeKeyDown';

export interface ModalProps {
  children: ReactElement | ReactNode;
  open: boolean;
  onClose?: (reason?: ModalCloseReason) => void;
  hideCloseButton?: boolean;
  overrideCloseButtonIcon?: ReactElement;
  disableAutoFocus?: boolean;
  moreWidth?: boolean;
  keepMounted?: boolean;
  header?: string;
  alignHeader?: 'left' | 'center' | 'right';
}

const StyledModal = styled(MuiModal)({
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  overflowY: 'auto',
  zIndex: 1300,
  inset: 0,
});

const OuterWrapper = styled(Box, {
  shouldForwardProp: propName => propName !== 'moreWidth',
})<{ moreWidth?: boolean }>(({ theme, moreWidth }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'absolute',
  outline: 'none',

  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  zIndex: 1301,

  pointerEvents: 'none',

  [theme.breakpoints.up('md')]: {
    maxWidth: moreWidth ? 1200 : 696,
    maxHeight: 'calc(100vh - 20px)',
    top: '10px',
    left: 0,
    right: 0,
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: moreWidth ? 'calc(100vw - 80px)' : 'calc(100vw - 40px)',
    maxHeight: 'calc(100vh - 10px)',
    top: '5px',
    left: 0,
    right: 0,
  },
}));

const InnerWrapper = styled(Box)(({ theme }) => ({
  height: 'auto',
  maxHeight: 'inherit',
  maxWidth: 'inherit',
  backgroundColor: theme.vars.palette.background.default,
  padding: `calc(${theme.vars.shape.borderRadius} * 4)`,
  borderRadius: 16,
  pointerEvents: 'auto',
}));

const Modal: FC<ModalProps> = ({
  open,
  onClose,
  children,
  disableAutoFocus,
  hideCloseButton,
  overrideCloseButtonIcon: CloseButtonOverride,
  moreWidth,
  keepMounted,
  header,
  alignHeader,
}) => {
  const handleClose = (
    _event: never,
    reason: 'backdropClick' | 'escapeKeyDown',
  ): void => {
    onClose?.(reason);
  };

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      disableAutoFocus={disableAutoFocus}
      keepMounted={keepMounted}
      slotProps={{
        backdrop: {
          style: { backgroundColor: 'rgba(0, 0, 0, 0.25)' },
        },
      }}
    >
      <OuterWrapper moreWidth={moreWidth}>
        <InnerWrapper>
          <Box height="100%" position="relative" overflow="auto" zIndex={1302}>
            <Box pb={2} height="40px">
              {!hideCloseButton ? (
                <IconButton
                  onClick={() => onClose?.()}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                  }}
                >
                  {CloseButtonOverride ? CloseButtonOverride : <CloseIcon />}
                </IconButton>
              ) : null}
              {header ? (
                <Typography
                  variant="h3"
                  textAlign={alignHeader ?? 'center'}
                  mt={1}
                  fontWeight={600}
                  pl={hideCloseButton || alignHeader !== 'left' ? 0 : '48px'}
                >
                  {header}
                </Typography>
              ) : null}
            </Box>
            <Box p={1}>{children}</Box>
          </Box>
        </InnerWrapper>
      </OuterWrapper>
    </StyledModal>
  );
};

export default Modal;
