import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type { AddOrganizationRequestBody } from 'redux-store/slices/organizations/types';

const addOrganizationThunk = createAsyncThunk(
  'organizations/addOrganizationThunk',
  async ({ id, name }: AddOrganizationRequestBody) => {
    try {
      const apiPointName = 'addOrganization';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: {
          id,
          name,
        },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default addOrganizationThunk;
