import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { HydrateAction } from 'redux-store';
import type { OrganizationsStateType } from 'redux-store/slices/organizations/types';
// to be updated
import getOrganizationByIdThunk from 'redux-store/thunks/organizations/getOrganizationByIdThunk';

const initialState = {
  isFetched: false,
  isFetching: false,
  items: [],
  selectedOrganization: {
    isFetched: false,
    isFetching: false,
    organization: null,
  },
} as OrganizationsStateType;

const OrganizationOverviewSlice = createSlice({
  name: 'organizationOverView',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getOrganizationByIdThunk.fulfilled, (state, action) => {
      state.selectedOrganization.isFetched = true;
      state.selectedOrganization.isFetching = false;

      if (action.payload.success) {
        state.selectedOrganization.organization = action.payload.organization;
      }
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    builder.addCase(
      HYDRATE,
      (state, action: HydrateAction<OrganizationsStateType>) => {
        return action.payload.organizations;
      },
    );
  },
});

export default OrganizationOverviewSlice.reducer;
