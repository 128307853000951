import type { PayloadAction } from '@reduxjs/toolkit';
import type { ApiErrorType, ApiSuccessType } from 'app.types';
import type { MacApiSuccessResponse } from 'utils/api/resolveMacAddress';
import type {
  CommonFullStatus,
  RawChargerFullStatus,
  RawControllerFullStatus,
  WithDeviceStatus,
} from 'utils/communication/fullstatus';
import type {
  ReadStateTemplate,
  ResponseMessage,
  WriteStateTemplate,
  WebSocketConnectionState,
} from 'utils/communication/websocketTypes';

import type { Countries } from 'constants/countries';

import type { DeviceSerial } from 'redux-store/slices/ui/types';

export type SerialOnlyAction = PayloadAction<{
  sse: string;
}>;

export type HandleApiKeyResponseAction = PayloadAction<{
  sse: string;
  response: ResponseMessage;
}>;

export type ClearApiKeyResponseSuccessAction = PayloadAction<{
  sse: string;
  response: ResponseMessage;
}>;

export type ClearApiKeyResponseAction = SerialOnlyAction;

export type GetApiMessageAction = PayloadAction<{
  sse: string;
  fullStatus: CommonFullStatus;
}>;

export type ClearApiKeyResponseForKeyAction = PayloadAction<{
  sse?: string;
  apiKey?: MergedValidApiKey;
}>;

export type ControllerPairUnpairAction = PayloadAction<{
  sse: string;
  controllerId: string;
}>;

export type SetMacVendorCacheKeyAction = PayloadAction<{
  mac: string;
  result: MacApiSuccessResponse;
}>;

export type SetWebsocketConnectionState = PayloadAction<{
  connectionState: WebSocketConnectionState;
}>;

export enum ForceState {
  Neutral = 0,
  Off = 1,
  On = 2,
}

export enum UnlockSetting {
  Normal,
  AutoUnlock,
  AlwaysLock,
}

export enum AccessControlSetting {
  Open,
  AuthRequired,
  Evcms,
}

export enum ChargingDurationInfoType {
  RunningSince,
  Paused,
}

export interface ChargingDurationInfo {
  type: ChargingDurationInfoType;
  value: number;
}

export enum ButtonAllowance {
  AlwaysLock,
  LockWhenCarIsConnected,
  LockWhenCarIsCharging,
  NeverLock,
}

export interface RFIDCard {
  name: string;
  energy: number;
  cardId: boolean | 'learn';
}

export type RFIDCards = RFIDCard[];

export enum Energy {
  voltageL1 = 0,
  voltageL2 = 1,
  voltageL3 = 2,
  voltageN = 3,
  currentL1 = 4,
  currentL2 = 5,
  currentL3 = 6,
  powerL1 = 7,
  powerL2 = 8,
  powerL3 = 9,
  powerN = 10,
  powerTotal = 11,
  powerFactorL1 = 12,
  powerFactorL2 = 13,
  powerFactorL3 = 14,
  powerFactorN = 15,
}

export type EnergyArray = [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
];

export interface LoadBalancingTotalAmpereObject {
  amp: number;
  ts: number;
  dyn?: number;
  sta?: number;
}

export type LoadBalancingTotalAmpere = number | LoadBalancingTotalAmpereObject;

export type Phase = 1 | 2 | 3 | 0;

export type LoadMapping = [Phase, Phase, Phase];

export const phaseListConstants: LoadMapping[] = [
  [1, 2, 3],
  [1, 3, 2],
  [2, 1, 3],
  [2, 3, 1],
  [3, 1, 2],
  [3, 2, 1],
  [1, 0, 0],
  [0, 1, 0],
  [0, 0, 1],
];
export interface SmartMeterData {
  ts: number;
  I1: number;
  I2: number;
  I3: number;
}

export enum SchedulerControl {
  Disabled,
  Allow,
  Block,
  AllowFromGrid,
  BlockFromGrid,
}

export interface SchedulerRangeTime {
  hour: number;
  minute: number;
  second: number;
}

export interface SchedulerRange {
  begin: SchedulerRangeTime;
  end: SchedulerRangeTime;
}

export interface Scheduler {
  control: SchedulerControl;
  ranges: [SchedulerRange, SchedulerRange]; // max. 2 ranges
}

export enum RoundingMode {
  PreferPowerFromGrid,
  Default,
  PreferPowerToGrid,
}

export enum DefaultCategories {
  Home,
  Grid,
  Car,
  Relais,
  Solar,
  Akku,
}

export type ChargerFullStatusState = RawChargerFullStatus | undefined;

export type ControllerCategoryNames = string[] | undefined;

export type ControllerCategoryPowers = (number | null)[] | undefined;

export type ControllerEnergyCountersReading = number[][] | undefined;
export type ControllerEnergyCountersWriting = (number[] | null)[];

export type ControllerCategoryPhaseCurrents = (number | null)[][] | undefined;

export type DefinedReadableChargerFullStatus =
  ReadStateTemplate<RawChargerFullStatus>;

export type ChargerFullStatusReading =
  | DefinedReadableChargerFullStatus
  | undefined;

export type DefinedWritableChargerFullStatusWriting =
  WriteStateTemplate<RawChargerFullStatus>;

export type ChargerFullStatusWriting =
  | DefinedWritableChargerFullStatusWriting
  | undefined;

export type DefinedReadableControllerFullStatus =
  ReadStateTemplate<RawControllerFullStatus>;

export type ControllerFullStatusReading =
  | DefinedReadableControllerFullStatus
  | undefined;

export type DefinedWritableControllerFullStatus =
  WriteStateTemplate<RawControllerFullStatus>;

export type ControllerFullStatusWriting =
  | DefinedWritableControllerFullStatus
  | undefined;

export type DeviceFullStatus =
  | ChargerFullStatusReading
  | ControllerFullStatusReading;

export type DefinedDeviceFullStatus = Exclude<
  WithDeviceStatus<DeviceFullStatus>,
  undefined
>;

export type _MergedFullStatus = RawChargerFullStatus & RawControllerFullStatus;

export type MergedFullStatusReading = ReadStateTemplate<_MergedFullStatus>;

export type MergedFullStatusWriting = WriteStateTemplate<_MergedFullStatus>;

export type ValidReadApiKey = keyof MergedFullStatusReading;

export type ValidReadApiKeyTypes = MergedFullStatusReading[ValidReadApiKey];

export type ValidWriteApiKey = keyof MergedFullStatusWriting;

export type ValidWriteApiKeyTypes = MergedFullStatusWriting[ValidWriteApiKey];

export type MergedValidApiKey = ValidReadApiKey | ValidWriteApiKey;

export type MergedValidApiKeyTypes =
  | ValidReadApiKeyTypes
  | ValidWriteApiKeyTypes;

export type ValidApiKey = MergedValidApiKey;

export type ValidApiKeyTypes = MergedValidApiKeyTypes;

export type ValidApiKeyTypeReading<T extends keyof MergedFullStatusReading> =
  MergedFullStatusReading[T];

export type ValidApiKeyTypeWriting<T extends keyof MergedFullStatusWriting> =
  MergedFullStatusWriting[T];

export type FullStatusRecord = Record<
  string,
  WithDeviceStatus<DeviceFullStatus>
>;

export interface WaitingRequest {
  requestId: string;
  apiKey: MergedValidApiKey;
  success: boolean | null;
  error?: string;
}

export type WaitingRequestType = WaitingRequest[];

interface DeviceCharts {
  isFetched: boolean;
  isFetching: boolean;
}

export interface DeviceChartsSuccess extends DeviceCharts {
  data: CloudData;
  isFetched: true;
  isFetching: false;
}

export interface DeviceChartsError extends DeviceCharts {
  data: LoadingCloudData;
  error?: string;
  isFetched: true;
  isFetching: false;
}

export interface DeviceChartsLoading extends DeviceCharts {
  data: LoadingCloudData;
  isFetched: false;
  isFetching: true;
}

export type DeviceChartsState =
  | DeviceChartsSuccess
  | DeviceChartsError
  | DeviceChartsLoading
  | undefined;

export interface TariffAPIResponse {
  id: number;
  country: Countries;
  name: string;
  companyname: string;
  decription: string;
  link: string;
  p_start: string;
  p: number[];
  p_interval: number;
  unit: string;
}

export interface TariffState {
  tariffData: TariffAPIResponse[];
  lastSuccessfulFetch: number;
}

export interface SuccessExportStatus {
  message: string;
  link?: string;
  filename?: string;
  progressBars: {
    color: string;
    progress: number;
    name: string;
  }[];
  finished_tasks?: number;
  length?: number;
  csv?: string;
  json?: {
    columns: {
      key: string;
      type?: string;
      hide?: boolean;
      unit?: string;
    }[];
    data: {
      session_number: number;
      session_identifier: string;
      id_chip: string;
      id_chip_uid: string;
      start: string;
      end: string;
      seconds_total: string;
      seconds_charged: string;
      max_power: number;
      max_current: number;
      energy: number;
      eto_start: number;
      eto_end: number;
      wifi: string;
    }[];
  };
  buttonClass?: string;
}

export interface ErrorExportStatus {
  status: string;
  message: undefined;
  link: undefined;
  filename: undefined;
  progressBars: undefined;
  finished_tasks: undefined;
  length: undefined;
  csv: undefined;
  json: undefined;
  buttonClass: undefined;
}

export interface ChargingSessionExport {
  ticket?: string;
  status?: SuccessExportStatus | ErrorExportStatus;
  finished: boolean;
  fetching: boolean;
}

export enum OcppConnectorState {
  Available = 0,
  Preparing = 1,
  Charging = 2,
  SuspendedEVSE = 3,
  SuspendedEV = 4,
  Finishing = 5,
  Reserved = 6,
  Unavailable = 7,
  Faulted = 8,
}

export type ExportTypes = ChargingSessionExport | undefined; // later also for controller

export type DeviceExportState = Record<string, ExportTypes>;

export interface ExportState {
  devices: DeviceExportState;
  error?: string;
}

interface DeviceTunnelInitializedStateType {
  wsReconnectCount: number;
  isInitialized: true;
  connectionState: WebSocketConnectionState;
  deviceCharts: Record<DeviceSerial, DeviceChartsState>;
  fullStatus: FullStatusRecord;
  waitingRequests: Record<DeviceSerial, WaitingRequestType | undefined>;
  tariffInfo: TariffState;
  macVendorCache: Record<DeviceSerial, MacApiSuccessResponse>;
  deviceExport: ExportState;
  changelog: Record<DeviceSerial, ChangelogData>;
}

interface DeviceTunnelUnInitializedStateType {
  wsReconnectCount: number;
  isInitialized: false;
  connectionState: WebSocketConnectionState;
  deviceCharts: Record<DeviceSerial, DeviceChartsState>;
  fullStatus: FullStatusRecord;
  waitingRequests: Record<DeviceSerial, WaitingRequestType>;
  tariffInfo: null;
  macVendorCache: null;
  deviceExport: ExportState;
  changelog: Record<DeviceSerial, ChangelogData>;
}

export type ApiStateType =
  | DeviceTunnelUnInitializedStateType
  | DeviceTunnelInitializedStateType;

export interface CloudData {
  success?: boolean;
  images?: string[]; // images with backend rendered charts
  chartData?: IRawChartData; // data for locally rendered charts
}

export interface LoadingCloudData {
  success: undefined;
  images: undefined;
  chartData: undefined;
}

export type GetDeviceChartsResponseType = ApiSuccessType<object> & {
  data: { sse: string; device: CloudData };
};

export interface GetDeviceChartsRequestBody {
  url: string;
  lang: string;
}

export interface ExportGetTicketSuccess {
  sse: string;
  ticket: string;
  success: true;
}

export interface ExportGetTicketError {
  success: false;
  message: string;
}

export type ExportGetTicketResponseType = ApiSuccessType<
  ExportGetTicketSuccess | ExportGetTicketError
>;

export type ExportGetStatusResponseType = ApiSuccessType<object> & {
  data: { success: true; status: SuccessExportStatus | ErrorExportStatus };
};

export type AwattarChartActualLineName =
  | 'awattar_price_past'
  | 'awattar_price_setting_past'
  | 'charging_current_past';

export type AwattarChartForecastLineName =
  | 'awattar_price_future'
  | 'awattar_price_setting_future'
  | 'charging_current_plan';

export type AmpereChartLineName =
  | 'ampere_l1'
  | 'ampere_l2'
  | 'ampere_l3'
  | 'ampere_setpoint';

export type VoltageChartLineName =
  | 'voltage_l1'
  | 'voltage_l2'
  | 'voltage_l3'
  | 'voltage_n';

export type EnergyChartLineName = 'energy_charged';

export type StatusChartLineName =
  | 'status_unknown'
  | 'status_no_car'
  | 'status_charging'
  | 'status_wait_for_car_allowed'
  | 'status_charging_not_allowed'
  | 'status_charging_finished_allowed'
  | 'status_error';

export type WiFiChartLineName = 'signal';

export type TemperatureChartLineName = 'type_2' | 'power_supply';

export type LoadBalancingCurrentLineName = `${number}_current`;
export type LoadBalancingLimitLineName = `${number}_limit`;

export type RawChartLineName =
  | AwattarChartActualLineName
  | AwattarChartForecastLineName
  | AmpereChartLineName
  | VoltageChartLineName
  | EnergyChartLineName
  | StatusChartLineName
  | WiFiChartLineName
  | TemperatureChartLineName;

export type RawChartType =
  | 'awattar'
  | 'ampere'
  | 'voltage'
  | 'energy'
  | 'status'
  | 'wifi'
  | 'temperature'
  | 'load-balancing';

export interface IRawChartValuePair {
  t: number;
  value: number | null;
}

export interface IRawChartDataSeriesItem<T, U> {
  name: T;
  unit: string;
  dataSet: IRawChartValuePair[];
  color: string;
  dashed: boolean;
  showLegend: boolean;
  linkedTo?: U;
  friendlyName: string;
}

export type RawChartLoadBalancingType = 'load-balancing';

export interface IRawChartLoadBalancing {
  type: RawChartLoadBalancingType;
  title: string;
  verticalLine: string | null;
  dataseries: IRawChartDataSeriesItem<
    LoadBalancingCurrentLineName | LoadBalancingLimitLineName,
    LoadBalancingLimitLineName
  >[];
}

export type RawChartAwattarType = 'awattar';

export interface IRawChartAwattar {
  type: RawChartAwattarType;
  title: string;
  verticalLine: string | null;
  dataseries: IRawChartDataSeriesItem<
    AwattarChartActualLineName | AwattarChartForecastLineName,
    AwattarChartForecastLineName
  >[];
}

export type RawChartAmpereType = 'ampere';

export interface IRawChartAmpere {
  type: RawChartAmpereType;
  title: string;
  verticalLine: string | null;
  dataseries: IRawChartDataSeriesItem<AmpereChartLineName, undefined>[];
}

export type RawChartVoltageType = 'voltage';

export interface IRawChartVoltage {
  type: RawChartVoltageType;
  title: string;
  verticalLine: string | null;
  dataseries: IRawChartDataSeriesItem<VoltageChartLineName, undefined>[];
}

export type RawChartEnergyType = 'energy';

export interface IRawChartEnergy {
  type: RawChartEnergyType;
  title: string;
  verticalLine: string | null;
  dataseries: IRawChartDataSeriesItem<EnergyChartLineName, undefined>[];
}

export type RawChartStatusType = 'status';

export interface IRawChartStatus {
  type: RawChartStatusType;
  title: string;
  verticalLine: string | null;
  dataseries: IRawChartDataSeriesItem<StatusChartLineName, undefined>[];
}

export type RawChartWlanType = 'wifi';

export interface IRawChartWlan {
  type: RawChartWlanType;
  title: string;
  verticalLine: string | null;
  dataseries: IRawChartDataSeriesItem<WiFiChartLineName, undefined>[];
}

export type RawChartTemperatureType = 'temperature';

export interface IRawChartTemperature {
  type: RawChartTemperatureType;
  title: string;
  verticalLine: string | null;
  dataseries: IRawChartDataSeriesItem<TemperatureChartLineName, undefined>[];
}

export interface IRawChartPanels {
  awattar?: IRawChartAwattar;
  loadBalancing?: IRawChartLoadBalancing;
  ampere?: IRawChartAmpere;
  voltage?: IRawChartVoltage;
  energy?: IRawChartEnergy;
  status?: IRawChartStatus;
  wifi?: IRawChartWlan;
  temperature?: IRawChartTemperature;
}

export interface IRawChartData {
  panels: IRawChartPanels;
  currentTime: number;
}

export enum DaylightSavingMode {
  None,
  EuropeanSummerTime,
  UsDaylightTime,
  AustralianDaylightTime,
}

export enum CableUnlockStatus {
  LockUnknown,
  LockUnlocked,
  LockUnlockFailed,
  LockLocked,
  LockLockFailed,
  LockUnlockPowerout,
}

export enum LogicMode {
  DONT_USE_0,
  DONT_USE_1,
  DONT_USE_2,
  Default,
  Awattar,
  NextTrip,
}

export interface ChangelogItem {
  version: string;
  versionCode: string;
  descriptions: (
    | string
    | {
        title: string;
        rows: string[];
        type: 'ordered-list' | 'unordered-list' | string;
      }
  )[];
}

export type ChangelogData = ChangelogItem[];

export enum ChangelogFormat {
  PlainText = '1',
  JSON = '2',
}

export interface GetChangelogDataParams {
  sse: NonNullable<ValidApiKeyTypeReading<'sse'>>;
  apd: NonNullable<ValidApiKeyTypeReading<'apd'>>;
  lang: string;
  format: ChangelogFormat;
}

export interface GetChangelogDataQueryParams {
  sse: NonNullable<ValidApiKeyTypeReading<'sse'>>;
  apd: NonNullable<ValidApiKeyTypeReading<'apd'>>['project_name'];
  lang: string;
  fwv: NonNullable<ValidApiKeyTypeReading<'apd'>>['version'];
  format: ChangelogFormat;
}

export type GetChangelogDataResponse =
  | ApiSuccessType<ChangelogData>
  | ApiErrorType;

export interface EnergyReportRequestDataParams {
  serials: string;
  from: string;
  to: string;
  format?: string;
  formatting?: string;
}

export interface ChargingSessionData {
  serial_number: string;
  session_id: string;
  session_number: number;
  session_identifier: string;
  deviceId: number;
  device_name: string;
  external_id_chip?: string;
  external_id_chip_uid?: string;
  external_id_chip_name?: string;
  rfidId?: number;
  start: string;
  end: string;
  seconds_total: string;
  seconds_charged: string;
  max_power: number | string;
  max_current: number | string;
  energy: number | string;
  eto_diff: number | string;
  eto_start: number | string;
  eto_end: number | string;
  wifi?: string;
  link?: string;
}

export interface EnergyReportResponseTypeSuccess {
  success: true;
  sessions: ChargingSessionData[];
  totalEnergy: string;
}

export interface EnergyReportResponseTypeError {
  success: false;
  error: string;
}

export type EnergyReportResponseType =
  | EnergyReportResponseTypeSuccess
  | EnergyReportResponseTypeError;
