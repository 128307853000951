import {
  Box,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import useDeviceGroupByDeviceId from 'hooks/useDeviceGroupByDeviceId';
import useIsOnline from 'hooks/useIsOnline';
import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import type { FC } from 'react';

import DeviceIconComponent from 'components/DeviceIconComponent';
import DeviceGroupsIcon from 'components/Icons/DeviceGroupsIcon';
import CustomCheckbox from 'components/MuiCustom/CustomCheckbox';

import type { PublicDatabaseDevice } from 'redux-store/slices/devices/types';

export interface DeviceListProps {
  device: PublicDatabaseDevice & { name?: string };
  selectedDeviceIds: number[];
  setSelectedDeviceIds: (devices: number[]) => void;
}

const CustomBox = styled(Box)(({ theme }) => ({
  // between every box there should be a border
  '&:not(:first-of-type)': {
    borderTop: `1px solid ${theme.vars.palette.grey[100]}`,
  },
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  cursor: 'pointer',
}));

const DeviceList: FC<DeviceListProps> = ({
  device,
  selectedDeviceIds,
  setSelectedDeviceIds,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCheckboxChange = useCallback(() => {
    if (selectedDeviceIds.includes(device.id)) {
      setSelectedDeviceIds(selectedDeviceIds.filter(id => id !== device.id));
    } else {
      setSelectedDeviceIds([...selectedDeviceIds, device.id]);
    }
  }, [device.id, selectedDeviceIds, setSelectedDeviceIds]);

  const online = useIsOnline(device.serial);
  const deviceGroups = useDeviceGroupByDeviceId(device.id);

  return (
    <CustomBox onClick={handleCheckboxChange}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          gap={1}
        >
          <CustomCheckbox
            checked={selectedDeviceIds.includes(device.id)}
            onChange={handleCheckboxChange}
            data-testid="add-device-to-group-checkbox"
          />
          <DeviceIconComponent typ={device.type} outlined />
          <Box display="flex" flexDirection="column">
            <Typography data-testid="device-title">
              {device.name ??
                t('common:serial_number_with_value', { value: device.serial })}
            </Typography>
            {device.name ? (
              <Typography variant="subtitle1" data-testid="device-serial">
                {t('common:serial_number_with_value', { value: device.serial })}
              </Typography>
            ) : !online ? (
              <Typography variant="subtitle1" data-testid="device-is-offline">
                {t(
                  'common:pages.device_groups.add_device_group.device_is_offline',
                )}
              </Typography>
            ) : null}
          </Box>
        </Box>
        {deviceGroups && deviceGroups.length > 0 && !smallScreen ? (
          <Box
            gap={1}
            maxWidth={200}
            flexDirection="row"
            display="flex"
            alignItems="center"
          >
            <DeviceGroupsIcon fontSize="small" sx={{ color: 'grey.500' }} />

            <Typography
              data-testid="device-group-names"
              variant="body1"
              noWrap
              color={theme.vars.palette.grey[500]}
            >
              {deviceGroups.map((group, index) => (
                <span key={group.id}>
                  {group.name}
                  {index < deviceGroups.length - 1 ? ', ' : null}
                </span>
              ))}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </CustomBox>
  );
};

export default DeviceList;
