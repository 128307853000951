import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type {
  AddDeviceToOrganizationRequestBody,
  GetDevicesByOrganizationIdResponseType,
  OrganizationId,
} from 'redux-store/slices/organizationOverview/types';

const addDevicesToOrganizationThunk = createAsyncThunk(
  'organizationOverview/addDevicesToOrganzation',
  async ({
    organizationId,
    data,
  }: {
    organizationId: OrganizationId;
    data: AddDeviceToOrganizationRequestBody;
  }): Promise<GetDevicesByOrganizationIdResponseType> => {
    try {
      const apiPointName = 'addDevicesToOrganzation';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: data,
        urlParams: { organizationId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default addDevicesToOrganizationThunk;
