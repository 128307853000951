import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useRef, useState } from 'react';
import type { FC } from 'react';

import { validEmailRegex } from 'constants/regex';

import Button from 'components/Button';
import FilledTextField from 'components/MuiCustom/FilledTextField';

import type { AddMemberToOrganizationModalStageProps } from '..';

const AddNewUser: FC<AddMemberToOrganizationModalStageProps> = ({
  state,
  setState,
  onCloseProxy,
}) => {
  const { email } = state;

  const { t } = useTranslation();

  const emailInputRef = useRef<HTMLInputElement>(null);

  const handleChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setError('');
      setState(prev => ({ ...prev, email: e.target.value }));
    },
    [setState],
  );

  const [error, setError] = useState<string | null>(null);

  const handleAddMemberEmail = useCallback(() => {
    if (!email) {
      setError(t('common:pages.organization_overview.add_user.email_required'));

      return;
    }

    if (email.length < 1 || !validEmailRegex.test(email)) {
      setError(t('common:login_errors.enter_vaild_email'));

      return;
    }

    setError(null);

    setState(prev => ({
      ...prev,
      stage: 'role',
    }));
  }, [email, setState, t]);

  return (
    <>
      <Box mb={4}>
        <Typography variant="h2" mb={2} textAlign="center">
          {t('common:pages.organization_overview.add_user.title')}
        </Typography>
        <Typography variant="body1" textAlign="center">
          {t('common:pages.organization_overview.add_user.description')}
        </Typography>
      </Box>
      <Box mb={4} width="100%" minHeight={150}>
        <form
          onSubmit={e => {
            e.preventDefault();
          }}
          data-testid="create-user-form"
        >
          <FilledTextField
            value={email}
            onChange={handleChangeEmail}
            fullWidth
            error={Boolean(error)}
            helperText={error}
            inputRef={emailInputRef}
            label={t('common:email')}
          />
        </form>
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={2}
        gap={2}
      >
        <Button
          variant="flat"
          color="neutral"
          size="large"
          onClick={onCloseProxy}
          data-testid="modal-previous-button"
        >
          {t('common:cancel')}
        </Button>
        <Button
          variant="flat"
          size="large"
          onClick={handleAddMemberEmail}
          disabled={!!error || email.length === 0}
          endIcon={<KeyboardArrowRightRoundedIcon />}
          data-testid="modal-next-button"
        >
          {t('common:next')}
        </Button>
      </Box>
    </>
  );
};

export default AddNewUser;
