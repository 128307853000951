import { TextField, Typography, Box } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import type { FC } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import pageUrls from 'constants/pageUrls';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import SuccessIcon from 'components/Icons/SuccessIcon';
import Modal from 'components/Modal';

import { useAppDispatch } from 'redux-store';
import addOrganizationThunk from 'redux-store/thunks/organizations/addOrganizationThunk';

export interface AddOrganizationModalProps extends DefaultPopupProps {
  organizationId?: number;
}

const AddOrganizationModal: FC<AddOrganizationModalProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { t, i18n } = useTranslation();

  const [name, setName] = useState<string>('');

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const serialInputRef = useRef<HTMLInputElement>(null);

  const handleAddOrganization = useCallback(
    async (event: { preventDefault: () => void }) => {
      event.preventDefault();
      if (isLoading) {
        return;
      }

      try {
        setLoading(true);
        const result = await dispatch(
          addOrganizationThunk({
            name,
          }),
        ).unwrap();

        if (!result.success) {
          if (i18n.exists(result.error)) {
            setError(t(result.error));
          } else {
            setError(result.error);
          }
        } else {
          await router.push(pageUrls.admin.organizations);
          setSuccess(true);
          setName('');

          setTimeout(() => {
            onClose();
            setSuccess(false);
          }, 1250);
        }
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            name,
          },
          tags: {
            page: 'AddOrganizationModal',
          },
        });
      } finally {
        setLoading(false);
      }
    },
    [isLoading, name, t, dispatch, i18n, router, onClose],
  );

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        serialInputRef.current?.focus();
      }, 100);
    }
  }, [open]);

  const validName = useMemo(() => name.length > 0, [name]);
  const valid = useMemo(() => validName && !error, [validName, error]);

  return (
    <Modal open={open} onClose={onClose} hideCloseButton={success}>
      {!success ? (
        <Box height="100%" sx={{ overflowY: 'auto' }}>
          <form onSubmit={handleAddOrganization}>
            <Box mb={3}>
              <Typography
                variant="h2"
                textAlign="center"
                mb={1}
                data-testid="add-organization-modal"
              >
                {t('common:pages.organization.add_organizations.add_new_org')}
              </Typography>
              <Typography variant="body1" textAlign="center">
                {t(
                  'common:pages.organization.add_organizations.enter_name_subtitle',
                )}
              </Typography>
            </Box>
            <Box mb={4}>
              <TextField
                label={t('common:pages.organization.organization_name')}
                name="name"
                value={name}
                onChange={(e): void => {
                  setError(null);
                  setName(e.target.value);
                }}
                inputProps={{ 'data-testid': 'organization-name' }}
                type="text"
                autoComplete="new-name"
                fullWidth
                variant="filled"
                disabled={isLoading}
              />
            </Box>
            <Box mb={2}>
              <Button
                fullWidth
                variant="flat"
                type="submit"
                disabled={!valid || isLoading}
                onClick={handleAddOrganization}
                size="large"
                data-testid="submit"
              >
                {t('common:add')}
              </Button>
            </Box>
            <ErrorText error={error} />
          </form>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          height="100%"
          sx={{ overflowY: 'auto' }}
        >
          <Box
            margin="50px 20px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <SuccessIcon width={100} height={100} />
            <Typography
              variant="h1"
              margin="20px 10px"
              textAlign="center"
              data-testid="success-text"
            >
              {t('common:pages.organization.add_organizations.success')}
            </Typography>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default AddOrganizationModal;
