import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type {
  OrganizationId,
  UpdateOrganizationRequestType,
  UpdateOrganizationResponseType,
} from 'redux-store/slices/organizations/types';

const updateOrganizationThunk = createAsyncThunk(
  'organizations/updateOrganizationThunk',
  async ({
    organizationId,
    data,
  }: {
    organizationId: OrganizationId;
    data: UpdateOrganizationRequestType;
  }): Promise<UpdateOrganizationResponseType> => {
    try {
      const apiPointName = 'updateOrganization';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: data,
        urlParams: { organizationId: organizationId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default updateOrganizationThunk;
