import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type {
  GetMemberByOrganizationIdResponseType,
  InviteMemberToOrganizationRequestBody,
  OrganizationId,
} from 'redux-store/slices/organizationOverview/types';

const inviteMemberToOrganizationThunk = createAsyncThunk(
  'organizationOverview/inviteMemberToOrganizationThunk',
  async ({
    organizationId,
    data,
  }: {
    organizationId: OrganizationId;
    data: InviteMemberToOrganizationRequestBody;
  }): Promise<GetMemberByOrganizationIdResponseType> => {
    try {
      const apiPointName = 'inviteMemberToOrganzation';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: data,
        urlParams: { organizationId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default inviteMemberToOrganizationThunk;
