import { createAsyncThunk } from '@reduxjs/toolkit';
import doFetch from 'utils/api/doFetch';

import type {
  AssignDevicesToMemberRequestBody,
  AssignDevicesToMemberResponseType,
  OrganizationId,
  OrganizationMemberId,
} from 'redux-store/slices/organizationOverview/types';

const assignDevicesToMemberThunk = createAsyncThunk(
  'organizationOverview/assignDevicesToMember',
  async ({
    organizationId,
    userId,
    data,
  }: {
    organizationId: OrganizationId;
    userId: OrganizationMemberId;
    data: AssignDevicesToMemberRequestBody;
  }): Promise<AssignDevicesToMemberResponseType> => {
    try {
      const apiPointName = 'assignDevicesToMember';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        body: data,
        urlParams: { organizationId, userId },
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export default assignDevicesToMemberThunk;
