import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import Modal from 'components/Modal';

import AddDeviceToUserAccount from './AddDeviceToUserAccount';
import AddNewDeviceContent from './AddNewDevice';
import AddNewUser from './AddNewUser';
import AddUserSuccess from './AddUserSuccess';
import AssignRoleToUser from './AssignRoleToUser';

type AddUserToOrganizationStage =
  | 'email'
  | 'role'
  | 'devices'
  | 'addDevice'
  | 'success';

const LineConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.vars.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.vars.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.vars.palette.grey[400],
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

interface AddMemberToOrganizationModalState {
  stage: AddUserToOrganizationStage;
  email: string;
  role: string;
  devices: number[];
  newUserID: number | null;
}

export interface AddMemberToOrganizationModalStageProps {
  open: boolean;
  state: AddMemberToOrganizationModalState;
  setState: Dispatch<SetStateAction<AddMemberToOrganizationModalState>>;
  onClose: () => void;
  onCloseProxy: () => void;
  organizationId: number | null;
  userId: number | null;
  selectedDevices: number[];
}

const StageComponents: Record<
  AddUserToOrganizationStage,
  FC<AddMemberToOrganizationModalStageProps>
> = {
  email: AddNewUser,
  role: AssignRoleToUser,
  devices: AddDeviceToUserAccount,
  addDevice: AddNewDeviceContent,
  success: AddUserSuccess,
};

interface Props extends DefaultPopupProps {
  organizationId: number | null;
  userId: number | null;
  selectedDevices: number[];
}

const AddMemberToOrganizationModal: FC<Props> = ({
  onClose,
  open,
  organizationId,
  userId,
  selectedDevices,
}) => {
  const { t } = useTranslation();

  const steps = [
    t('common:pages.organization_overview.add_user.information'),
    t('common:pages.organization_overview.add_user.role'),
    t('common:pages.organization_overview.add_user.devices'),
  ];

  const [state, setState] = useState<AddMemberToOrganizationModalState>({
    stage: 'email',
    email: '',
    role: '',
    devices: [],
    newUserID: null,
  });

  const stepMap: Record<string, number> = {
    email: 0,
    role: 1,
    devices: 2,
  };

  const activeStep = (step: string): number => {
    return stepMap[step] ?? 2;
  };

  useEffect(() => {
    if (!open) {
      setState(prev => ({ ...prev, email: '', role: '', devices: [] }));
    }
  }, [open]);

  const StageComponent = useMemo(() => StageComponents[state.stage], [state]);

  const onCloseProxy = useCallback((): void => {
    if (state.stage === 'email') {
      setState(prev => ({
        ...prev,
        email: '',
        role: '',
        devices: [],
        addDevice: '',
      }));

      onClose();
    } else if (state.stage === 'role') {
      setState(prev => ({
        ...prev,
        stage: 'email',
      }));
    } else if (state.stage === 'devices') {
      setState(prev => ({
        ...prev,
        stage: 'role',
      }));
    } else if (state.stage === 'addDevice') {
      setState(prev => ({
        ...prev,
        stage: 'devices',
      }));
    }
  }, [onClose, state]);

  return (
    <Modal
      open={open}
      onClose={onCloseProxy}
      overrideCloseButtonIcon={
        state.stage !== 'email' ? <ArrowBackRoundedIcon /> : undefined
      }
      hideCloseButton={state.stage === 'success'}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        sx={{ overflowY: 'auto' }}
      >
        <Box pb={4} width="100%">
          {state.stage !== 'addDevice' ? (
            <Stepper
              alternativeLabel
              activeStep={activeStep(state.stage)}
              sx={{ marginX: -1 }}
              connector={<LineConnector />}
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          ) : null}
        </Box>
        <StageComponent
          open={open}
          setState={setState}
          state={state}
          onClose={onClose}
          onCloseProxy={onCloseProxy}
          organizationId={organizationId}
          userId={userId}
          selectedDevices={selectedDevices}
        />
      </Box>
    </Modal>
  );
};

export default AddMemberToOrganizationModal;
