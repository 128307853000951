import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { formatTime } from 'utils/common/formatTime';

export type DecimalSeparator = 'point' | 'comma';
interface TableFilterState {
  fromTime: string;
  toTime: string;
  decimalSeparator: DecimalSeparator;
  rowsPerPage: number;
}

const initialState: TableFilterState = {
  fromTime: formatTime(moment().startOf('month')),
  toTime: formatTime(moment()),
  decimalSeparator: 'comma',
  rowsPerPage: 10,
};

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFromTime: (state, action: PayloadAction<string>) => {
      state.fromTime = action.payload;
    },
    setToTime: (state, action: PayloadAction<string>) => {
      state.toTime = action.payload;
    },
    setDecimalSeparator: (state, action: PayloadAction<DecimalSeparator>) => {
      state.decimalSeparator = action.payload;
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
    },
  },
});

export const { setFromTime, setToTime, setDecimalSeparator, setRowsPerPage } =
  filterSlice.actions;
export default filterSlice.reducer;
