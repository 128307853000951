import { Box, TextField, Typography } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import type { AddRfidGroupModalStageProps } from 'content/modals/AddRfidGroupModal';
import useRfidsAssignedToGroups from 'hooks/useRfidsAssignedToGroups';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import rfidGroupsComparator from 'utils/helpers/rfidGroupsComparator';
import rfidItemsComparator from 'utils/helpers/rfidItemsComparator';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';

import { useAppDispatch, useAppSelector } from 'redux-store';
import type { RfidId } from 'redux-store/slices/ui/types';
import addRfidGroupThunk from 'redux-store/thunks/rfidGroups/addRfidGroupThunk';
import getAllRfidGroupsThunk from 'redux-store/thunks/rfidGroups/getAllRfidGroupsThunk';
import moveRfidToRfidGroupThunk from 'redux-store/thunks/rfidGroups/moveRfidToRfidGroupThunk';

const AddRfidGroupSetNameContent: FC<AddRfidGroupModalStageProps> = ({
  state,
  setState,
  onClose,
  open,
}) => {
  const { name } = state;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const groupNameInputRef = useRef<HTMLInputElement>(null);

  const rfidGroups = useAppSelector(
    appState => appState.rfidGroups.items,
    (a, b) => rfidGroupsComparator()(a, b),
  );

  const rfids = useAppSelector(
    appState => appState.rfids.items,
    (a, b) => rfidItemsComparator()(a, b),
  );

  const rfidsAssignedToGroups = useRfidsAssignedToGroups();

  const [error, setError] = useState<string | null>(null);
  const [inputError, setInputError] = useState<string | null>(null);

  const validateName = useCallback(
    (inputName: string): string => {
      if (!inputName) {
        return t(
          'common:pages.rfid_groups.add_rfid_group.errors.name_required',
        );
      }

      if (inputName.length > 50) {
        return t(
          'common:pages.rfid_groups.add_rfid_group.errors.group_name_max_length',
        );
      }

      if (rfidGroups.map(g => g.name).includes(inputName)) {
        return t(
          'common:pages.device_groups.add_device_group.errors.name_already_exists',
        );
      }

      return '';
    },
    [rfidGroups, t],
  );

  const handleChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newName = e.target.value;
      const validationError = validateName(newName);
      setInputError(validationError);
      setState(prev => ({ ...prev, name: newName }));
    },
    [setState, validateName],
  );

  const handleContinue = useCallback(async () => {
    const validationError = validateName(name);

    if (validationError) {
      setInputError(validationError);

      return;
    }

    setInputError(null);
    setError(null);

    if (state.rfids.length) {
      let result = undefined;

      const rfidsWithoutGroups = rfids.filter(
        d =>
          state.rfids.includes(d.id) && !rfidsAssignedToGroups.includes(d.id),
      );

      const rfidsWithGroups = rfids.filter(
        d => state.rfids.includes(d.id) && rfidsAssignedToGroups.includes(d.id),
      );

      try {
        result = await dispatch(
          addRfidGroupThunk({
            rfidGroup: {
              name: state.name,
              rfids: rfidsWithoutGroups.map(d => d.id),
            },
          }),
        ).unwrap();

        if (!result.success) {
          setError(
            t(
              'common:pages.rfid_groups.add_rfid_group.errors.error_creating_group_with_error',
              { error: result.error },
            ),
          );

          return;
        }

        const groupId = result.rfidGroup.id;

        const errors: { rfidId: RfidId; error: string }[] = [];

        const handleMoveRfid = async (rId: RfidId): Promise<void> => {
          const rfidId = rfids.find(r => r.id === rId)?.id;

          if (!rfidId) {
            return;
          }

          const res = await dispatch(
            moveRfidToRfidGroupThunk({
              rfidId,
              data: { targetRfidGroup: groupId },
            }),
          ).unwrap();

          if (!res.success) {
            errors.push({ rfidId, error: res.error });
          }
        };

        const promises: Promise<void>[] = [];

        rfidsWithGroups.forEach(rfid => {
          promises.push(handleMoveRfid(rfid.id));
        });

        await Promise.all(promises);

        if (errors.length) {
          setError(
            t(
              'common:pages.rfid_groups.add_rfid_group.errors.error_moving_rfids_to_group',
              { errors: errors.map(e => e.error).join(', ') },
            ),
          );

          return;
        }

        setError(null);

        setState({ name: '', rfids: [], stage: 'success' });

        await dispatch(getAllRfidGroupsThunk({})).unwrap();

        setTimeout(() => {
          setState({ name: '', rfids: [], stage: 'name' });
          onClose();
        }, 1250);
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            rfidsWithoutGroups,
            state,
            result,
          },
        });
      }
    } else {
      setState(prev => ({ ...prev, stage: 'rfids' }));
    }
  }, [
    validateName,
    name,
    state,
    rfids,
    rfidsAssignedToGroups,
    dispatch,
    setState,
    t,
    onClose,
  ]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        groupNameInputRef.current?.focus();
      }, 100);
    }
  }, [open]);

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ overflowY: 'auto' }}
    >
      <Box mb={4} width="100%">
        <Typography
          variant="h2"
          textAlign="center"
          mb={2}
          data-testid="add-rfid-group-modal-title"
        >
          {t('common:pages.rfid_groups.add_rfid_group.title')}
        </Typography>
        <Typography textAlign="center" variant="body1" mb={2}>
          {t('common:pages.rfid_groups.add_rfid_group.enter_name_subtitle')}
        </Typography>
      </Box>
      <Box mb={4} width="100%">
        <form
          onSubmit={e => {
            e.preventDefault();
            handleContinue();
          }}
        >
          <TextField
            variant="filled"
            fullWidth
            label={t('common:pages.rfid_groups.add_rfid_group.group_name')}
            value={name}
            onChange={handleChangeName}
            error={Boolean(inputError)}
            helperText={inputError}
            inputRef={groupNameInputRef}
            inputProps={{ 'data-testid': 'add-rfid-group-modal-name-input' }}
          />
        </form>
      </Box>
      <ErrorText error={error} mt={2} />
      <Box width="100%" display="flex" flexDirection="row" gap={2} pt={2}>
        <Button
          variant="flat"
          color="neutral"
          fullWidth
          size="large"
          onClick={onClose}
          data-testid="cancel-button"
        >
          {t('common:cancel')}
        </Button>
        <Button
          variant="flat"
          fullWidth
          size="large"
          onClick={handleContinue}
          disabled={Boolean(inputError)}
          data-testid="continue-button"
        >
          {t('common:continue')}
        </Button>
      </Box>
    </Box>
  );
};

export default AddRfidGroupSetNameContent;
