import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, Typography, styled } from '@mui/material';
import DeviceTypeCount from 'content/pages/OrganizationOverviewPage/OrganizationMembers/DeviceTypeCount';
import { useCallback } from 'react';
import type { FC } from 'react';

import CustomCheckbox from 'components/MuiCustom/CustomCheckbox';

import { useAppDispatch } from 'redux-store';
import type { OrganizationMembersDatabase } from 'redux-store/slices/organizationOverview/types';
import { setAssignMembersToDevicesModal } from 'redux-store/slices/ui';

export interface MemberListProps {
  member: OrganizationMembersDatabase;
  selectedMembers: number[];
  organizationId: number | null;
  deviceId: number | null;
  userId: number | null;
  setSelectedMembers: (member: number[]) => void;
}

const CustomBox = styled(Box)(({ theme }) => ({
  '&:not(:first-of-type)': {
    borderTop: `1px solid ${theme.vars.palette.grey[100]}`,
  },
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  cursor: 'pointer',
}));

const MemberList: FC<MemberListProps> = ({
  member,
  organizationId,
  deviceId,
  selectedMembers,
  setSelectedMembers,
}) => {
  const dispatch = useAppDispatch();

  const handleCheckboxChange = useCallback(() => {
    if (selectedMembers.includes(member.id)) {
      setSelectedMembers(selectedMembers.filter(id => id !== member.id));
    } else {
      setSelectedMembers([...selectedMembers, member.id]);
    }
  }, [member.id, selectedMembers, setSelectedMembers]);

  const openAssignDevicesModal = useCallback(() => {
    if (!organizationId) return;

    return dispatch(
      setAssignMembersToDevicesModal({
        isOpen: true,
        organizationId: organizationId,
        deviceId: deviceId,
        selectedMembers: selectedMembers,
      }),
    );
  }, [organizationId, dispatch, deviceId, selectedMembers]);

  return (
    <CustomBox onClick={handleCheckboxChange}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          gap={1}
        >
          <CustomCheckbox
            checked={selectedMembers.includes(member.id)}
            onChange={handleCheckboxChange}
          />
          <Box display="flex" flexDirection="row" gap={1}>
            <PersonOutlineOutlinedIcon />
            <Typography data-testid="device-title">{member.email}</Typography>
          </Box>
        </Box>
        <Box
          gap={1}
          maxWidth={200}
          flexDirection="row"
          display="flex"
          alignItems="center"
        >
          <DeviceTypeCount
            devices={member.devices}
            openAssignDevicesModal={openAssignDevicesModal}
          />
        </Box>
      </Box>
    </CustomBox>
  );
};

export default MemberList;
