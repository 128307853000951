import type { Moment } from 'moment';
import moment from 'moment';

export const formatTime = (value: Moment): string => {
  const time = moment(value).format('YYYY-MM-DDTHH:mm:ss');

  return time;
};

export const formatToDisplayDateTime = (value: string): string => {
  const time = moment(value).format('DD.MM.YYYY HH:mm:ss');

  return time;
};
