import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, IconButton, styled, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

interface CustomBoxProps {
  backgroundColor?: string;
  hoverOpacity?: number;
}

const CustomBox = styled(Box)<CustomBoxProps>(
  ({ backgroundColor, hoverOpacity = 1 }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    backgroundColor: backgroundColor,
    padding: '5px 10px',
    borderRadius: 24,
    '&:hover': {
      opacity: hoverOpacity,
    },
  }),
);

interface AddButtonProps {
  handleClick: () => void;
}

const AddButton: FC<AddButtonProps> = ({ handleClick }) => {
  const { t } = useTranslation();

  return (
    <CustomBox backgroundColor="#E9F1FF" onClick={handleClick}>
      <IconButton sx={{ padding: 0 }} disableRipple>
        <AddCircleOutlineIcon color="primary" />
      </IconButton>
      <Typography color="primary">{t('common:assign')}</Typography>
    </CustomBox>
  );
};

export default AddButton;
