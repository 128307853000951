import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import Modal from 'components/Modal';

import type { DeviceSerial } from 'redux-store/slices/ui/types';

import AddDeviceGroupSetDevicesContent from './AddDeviceGroupSetDevicesContent';
import AddDeviceGroupSetNameContent from './AddDeviceGroupSetNameContent';
import AddDeviceGroupSuccess from './AddDeviceGroupSuccess';
import AddNewDeviceContent from './AddNewDeviceContent';

export type AddDeviceGroupStage = 'name' | 'devices' | 'addDevice' | 'success';

export interface AddDeviceGroupModalState {
  stage: AddDeviceGroupStage;
  name: string;
  devices: DeviceSerial[];
}

export interface AddDeviceGroupModalStageProps {
  open: boolean;
  state: AddDeviceGroupModalState;
  groupID: number | null;
  setState: Dispatch<SetStateAction<AddDeviceGroupModalState>>;
  onClose: () => void;
  onCloseProxy: () => void;
}

const StageComponents: Record<
  AddDeviceGroupStage,
  FC<AddDeviceGroupModalStageProps>
> = {
  name: AddDeviceGroupSetNameContent,
  devices: AddDeviceGroupSetDevicesContent,
  addDevice: AddNewDeviceContent,
  success: AddDeviceGroupSuccess,
};

export interface AddDeviceGroupModalProps extends DefaultPopupProps {
  preselectedDevices?: DeviceSerial[];
  groupID: number | null;
}

const AddDeviceGroupModal: FC<AddDeviceGroupModalProps> = ({
  open,
  onClose,
  preselectedDevices,
  groupID,
}) => {
  const [state, setState] = useState<AddDeviceGroupModalState>({
    stage: groupID ? 'devices' : 'name',
    name: '',
    devices: [],
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (open && preselectedDevices) {
      setState(prev => ({
        ...prev,
        devices: preselectedDevices,
        stage: groupID ? 'devices' : 'name',
      }));
    }
  }, [groupID, open, preselectedDevices]);

  useEffect(() => {
    if (!open) {
      setState(prev => ({
        ...prev,
        name: '',
        stage: 'name',
        devices: [],
      }));
    }
  }, [groupID, open]);

  const StageComponent = useMemo(() => StageComponents[state.stage], [state]);

  const onCloseProxy = useCallback((): void => {
    switch (state.stage) {
      case 'name':
        setState(prev => ({ ...prev, name: '', devices: [] }));
        onClose();
        break;

      case 'devices':
        if (groupID) {
          setState(prev => ({ ...prev, name: '', devices: [] }));
          onClose();
        } else {
          setState(prev => ({ ...prev, stage: 'name', devices: [] }));
        }
        break;

      case 'addDevice':
        setState(prev => ({ ...prev, stage: 'devices' }));
        break;

      default:
        break;
    }
  }, [groupID, onClose, state.stage]);

  const goBackButtonIcon = useCallback(() => {
    switch (state.stage) {
      case 'addDevice':
        return <ArrowBackRoundedIcon />;
      case 'devices':
        if (groupID) {
          return undefined;
        } else {
          return <ArrowBackRoundedIcon />;
        }
      default:
        return undefined;
    }
  }, [groupID, state.stage]);

  return (
    <Modal
      open={open}
      onClose={onCloseProxy}
      overrideCloseButtonIcon={goBackButtonIcon()}
      header={
        state.stage === 'addDevice'
          ? t('common:pages.device_groups.add_device_group.add_to_group')
          : ''
      }
      hideCloseButton={state.stage === 'success'}
    >
      <StageComponent
        open={open}
        setState={setState}
        state={state}
        groupID={groupID}
        onClose={onClose}
        onCloseProxy={onCloseProxy}
      />
    </Modal>
  );
};

export default AddDeviceGroupModal;
