import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import {
  Box,
  IconButton,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import Fuse from 'fuse.js';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useDebounce } from 'use-debounce';

import Button from 'components/Button';
import CustomSearchbar from 'components/CustomSearchBar';
import ErrorText from 'components/ErrorText';

import { useAppDispatch, useAppSelector } from 'redux-store';
import assignDevicesToMemberThunk from 'redux-store/thunks/organizationOverview/assignDevicesToMemberThunk';
import getOrganizationByIdThunk from 'redux-store/thunks/organizations/getOrganizationByIdThunk';

import type { AssignDevicesToMembersModalStageProps } from '..';
import DeviceList from './DeviceList';

const AssignDevicesToMembers: FC<AssignDevicesToMembersModalStageProps> = ({
  state,
  setState,
  onCloseProxy,
  onClose,
  organizationId,
  userId,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [searchString, setSearchString] = useState('');
  const [searchResults, setSearchResults] = useState<string[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const deviceList = useAppSelector(s => s.devices.items);

  const fullStatus = useAppSelector(appState => appState.api.fullStatus);

  const devicesWithNames = useMemo(() => {
    return deviceList.map(device => ({
      ...device,
      name: fullStatus[device.serial]?.fna,
    }));
  }, [deviceList, fullStatus]);

  const fuse = useMemo(
    () =>
      new Fuse(devicesWithNames, {
        keys: ['name', 'serial'],
        threshold: 0.4,
      }),
    [devicesWithNames],
  );

  const handleSearch = useCallback((): void => {
    if (!searchString) {
      setSearchResults(null);

      return;
    }

    const results = fuse.search(searchString);

    setSearchResults(results.map(result => result.item.serial));
  }, [fuse, searchString]);

  const [debouncedSearch] = useDebounce(searchString, 500);

  useEffect(() => {
    if (debouncedSearch) {
      handleSearch();
    }
  }, [debouncedSearch, handleSearch]);

  const handleClearSearch = useCallback((): void => {
    setSearchString('');
    setSearchResults(null);
  }, []);

  const selectedDeviceIds = useMemo(() => state.devices, [state.devices]);
  const setSelectedDeviceIds = useCallback(
    (devices: number[]) => {
      setState(prev => ({ ...prev, devices }));
    },
    [setState],
  );

  const filteredDevices = useMemo(
    () =>
      searchResults
        ? devicesWithNames.filter(device =>
            searchResults.includes(device.serial),
          )
        : devicesWithNames,
    [devicesWithNames, searchResults],
  );

  const handleAssignDevicesToMember = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      if (!organizationId || !userId) return;

      try {
        setLoading(true);

        const result = await dispatch(
          assignDevicesToMemberThunk({
            organizationId: organizationId,
            userId: userId,
            data: {
              devices: selectedDeviceIds,
            },
          }),
        ).unwrap();

        if (result.success) {
          await dispatch(
            getOrganizationByIdThunk({
              organizationId: organizationId,
            }),
          ).unwrap();
          setState({ devices: [], stage: 'success' });

          setTimeout(() => {
            setState({ devices: [], stage: 'devices' });
            onClose();
          }, 1250);
        } else {
          setError('Error'); //  to be defined for each device
        }
      } catch (e) {
        Sentry.captureException(e, {
          tags: { page: 'AssignDevicesToMembersModal' },
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatch, onClose, organizationId, selectedDeviceIds, setState, userId],
  );

  return (
    <>
      <Box
        sx={{
          height: '45vh',
          overflow: 'auto',
          paddingBottom: 5,
          width: '100%',
        }}
      >
        <Box mb={4}>
          <Typography variant="h2" mb={2} textAlign="center">
            {t(
              'common:pages.organization_overview.assign_devices_to_member.title',
            )}
          </Typography>
        </Box>
        <Box mb={4} width="100%">
          <ListItemButton
            sx={{
              backgroundColor: theme.vars.palette.grey[100],
              borderRadius: 2,
            }}
            onClick={() => setState(prev => ({ ...prev, stage: 'addDevice' }))}
          >
            <ListItemText
              color="primary"
              primaryTypographyProps={{
                style: {
                  color: theme.vars.palette.primary.dark,
                  fontWeight: 500,
                },
              }}
              primary={t('common:add_device')}
            />
            <IconButton edge="end" disableRipple>
              <AddCircleOutlineRoundedIcon color="primary" />
            </IconButton>
          </ListItemButton>
        </Box>
        <Typography variant="h3" mb={1}>
          {t('common:pages.organization_overview.add_user.your_devices')}
        </Typography>
        <Box>
          {devicesWithNames.length > 0 ? (
            <Box>
              <Box mb={3} width="100%">
                <CustomSearchbar
                  search={searchString}
                  setSearch={setSearchString}
                  hasSearched={searchString.length > 0}
                  handleSearch={handleSearch}
                  handleClearSearch={handleClearSearch}
                />
              </Box>
              {filteredDevices.map(item => (
                <DeviceList
                  key={item.id}
                  device={item}
                  selectedDeviceIds={selectedDeviceIds}
                  setSelectedDeviceIds={setSelectedDeviceIds}
                />
              ))}
            </Box>
          ) : (
            <Typography variant="body1" textAlign="center">
              {t(
                'common:pages.organization_overview.add_user.no_devices_found',
              )}
            </Typography>
          )}
          {/* {error?.map(e => (
            <ErrorText key={e.serial} error={`SN ${e.serial} : ${e.error}`} />
          ))} */}
          <ErrorText error={error} />
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={2}
        gap={2}
      >
        <Button
          variant="flat"
          color="neutral"
          size="large"
          fullWidth
          onClick={onCloseProxy}
          data-testid="modal-cancel-button"
        >
          {t('common:cancel')}
        </Button>
        <Button
          variant="flat"
          size="large"
          fullWidth
          disabled={isLoading}
          onClick={handleAssignDevicesToMember}
          data-testid="modal-save-button"
        >
          {t('common:save')}
        </Button>
      </Box>
    </>
  );
};

export default AssignDevicesToMembers;
