import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  Box,
} from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import useProfile from 'hooks/useProfile';
import { useTranslation } from 'next-i18next';
import { useCallback, useRef, useState, useEffect } from 'react';
import type { FC } from 'react';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import CustomCheckbox from 'components/MuiCustom/CustomCheckbox';

import { useAppDispatch } from 'redux-store';
import getAllDevicesThunk from 'redux-store/thunks/devices/getAllDevicesThunk';
import addDevicesToOrganizationThunk from 'redux-store/thunks/organizationOverview/addDevicesToOrganizationThunk';
import getOrganizationByIdThunk from 'redux-store/thunks/organizations/getOrganizationByIdThunk';

import type { AssignDevicesToMembersModalStageProps } from '..';

export interface AddNewDeviceProps
  extends AssignDevicesToMembersModalStageProps {
  organizationId: number | null;
}

const AddNewDevice: FC<AddNewDeviceProps> = ({
  open,
  onClose,
  organizationId,
  setState,
}) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const profile = useProfile();

  const [serialNumber, setSerialNumber] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [addDeviceToAdmin, setAddDeviceToAdmin] = useState<boolean>(false);

  const serialInputRef = useRef<HTMLInputElement>(null);

  const handleAddDevice = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      if (
        isLoading ||
        serialNumber.length < 6 ||
        !password.length ||
        !organizationId
      ) {
        return;
      }

      try {
        setLoading(true);

        const result = await dispatch(
          addDevicesToOrganizationThunk({
            organizationId: organizationId,
            data: { devices: [{ serial: serialNumber, password }] },
          }),
        ).unwrap();

        if (result.success) {
          if (profile) {
            await dispatch(getAllDevicesThunk({ userId: profile.id }));
          }
          await dispatch(
            getOrganizationByIdThunk({ organizationId: organizationId }),
          ).unwrap();
          setState(prev => ({
            ...prev,
            // devices: [...prev.devices, serialNumber], // update serial with id
            stage: 'devices',
          }));

          onClose();

          setSerialNumber('');
          setPassword('');
        } else {
          const errorMsg = i18n.exists(result.error)
            ? t(result.error)
            : result.error;
          setError(errorMsg);
        }
      } catch (e) {
        Sentry.captureException(e, {
          extra: { serialNumber },
          tags: { page: 'AddNewDeviceModal' },
        });
      } finally {
        setLoading(false);
      }
    },
    [
      serialNumber,
      isLoading,
      password,
      organizationId,
      t,
      dispatch,
      profile,
      setState,
      onClose,
      i18n,
    ],
  );

  useEffect(() => {
    if (open) {
      serialInputRef.current?.focus();
    }
  }, [open]);

  const validSerialNumber =
    serialNumber.length >= 6 && serialNumber.length <= 10;
  const validPassword = password.length > 0;
  const valid = validSerialNumber && validPassword && !error;

  return (
    <Box height="100%" width="100%" sx={{ overflowY: 'auto' }}>
      <form onSubmit={() => handleAddDevice}>
        <Box mb={3}>
          <Typography
            variant="h2"
            textAlign="center"
            data-testid="new-device-title"
            mb={2}
          >
            {t('common:add_new_device')}
          </Typography>
          <Typography variant="body1" textAlign="center">
            {t('common:add_new_device_description')}
            {typeof organizationId === 'number'
              ? t('common:add_new_device_device_group_description')
              : null}
          </Typography>
        </Box>
        <Box mb={2}>
          <TextField
            label={t('common:serial_number')}
            name="serial"
            value={serialNumber}
            onChange={(e): void => {
              setError(null);
              setSerialNumber(e.target.value);
            }}
            type="text"
            autoComplete="off"
            fullWidth
            inputProps={{
              maxLength: 10,
              'data-testid': 'new-device-serial',
            }}
            variant="filled"
            disabled={isLoading}
            inputRef={serialInputRef}
          />
        </Box>
        <Box mb={4}>
          <TextField
            label={t('common:password')}
            name="password"
            value={password}
            onChange={(e): void => {
              setError(null);
              setPassword(e.target.value);
            }}
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(): void => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <VisibilityOff fontSize="small" />
                    ) : (
                      <Visibility fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              inputProps: { 'data-testid': 'new-device-password' },
            }}
            fullWidth
            variant="filled"
            disabled={isLoading}
          />
        </Box>
        <Box mb={4} display="flex" flexDirection="row" alignItems="center">
          <CustomCheckbox
            checked={addDeviceToAdmin}
            onChange={() => setAddDeviceToAdmin(!addDeviceToAdmin)}
            data-testid="add-device-to-admin-accont"
          />
          <Typography variant="body1">
            {t('common:pages.organization_overview.add_user.add_to_admin')}
          </Typography>
        </Box>
        <ErrorText error={error} />
        <Box width="100%" display="flex" flexDirection="row" mt={2} gap={2}>
          <Button
            fullWidth
            color="neutral"
            variant="flat"
            type="submit"
            size="large"
            onClick={onClose}
            data-testid="cancel"
          >
            {t('common:cancel')}
          </Button>
          <Button
            fullWidth
            variant="flat"
            type="submit"
            onClick={handleAddDevice}
            disabled={!valid || isLoading}
            size="large"
            data-testid="submit"
          >
            {t('common:add')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddNewDevice;
