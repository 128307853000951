import { useMemo } from 'react';

import { useAppSelector } from 'redux-store';
import type { PublicDatabaseDeviceGroup } from 'redux-store/slices/deviceGroups/types';

const useDeviceGroupByDeviceId = (
  deviceId?: number,
): PublicDatabaseDeviceGroup[] | undefined => {
  const deviceGroups = useAppSelector(state => state.deviceGroups.items);

  return useMemo(() => {
    if (typeof deviceId !== 'undefined') {
      return deviceGroups.filter(group => group.devices?.includes(deviceId));
    }

    return undefined;
  }, [deviceGroups, deviceId]);
};

export default useDeviceGroupByDeviceId;
