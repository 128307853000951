import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';
import doFetch from 'utils/api/doFetch';

import type {
  GetOrganizationByIdResponseType,
  OrganizationId,
} from 'redux-store/slices/organizations/types';

const getOrganizationByIdThunk = createAsyncThunk(
  'organizations/getOrganizationByIdThunk',
  async ({
    context,
    organizationId,
  }: {
    context?: GetServerSidePropsContext;
    organizationId: OrganizationId;
    userId?: string;
  }): Promise<GetOrganizationByIdResponseType> => {
    try {
      const apiPointName = 'getOrganizationById';

      return await doFetch<typeof apiPointName>({
        apiPointName,
        context,
        urlParams: { id: organizationId },
      });
    } catch (e) {
      console.error('error', e);

      return Promise.reject(e);
    }
  },
);

export default getOrganizationByIdThunk;
