import { useAppSelector } from 'redux-store';
import type { PublicDatabaseOrganization } from 'redux-store/slices/organizations/types';

const useOrganizations = (
  organizationId?: number,
): PublicDatabaseOrganization | undefined =>
  useAppSelector(state =>
    state.organizations.items.find(org => org.id === organizationId),
  );

export default useOrganizations;
