import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import type { FC } from 'react';
import { formatDynamicRoute } from 'utils/common/formatDynamicRoute';
import type { DefaultPopupProps } from 'utils/props';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import CustomTrashIcon from 'components/Icons/CustomTrashIcon';
import SuccessIcon from 'components/Icons/SuccessIcon';
import Modal from 'components/Modal';

import { useAppDispatch } from 'redux-store';
import { removeDeviceOverviewSelectedDevice } from 'redux-store/slices/ui';
import type { DeviceSerial } from 'redux-store/slices/ui/types';
import deleteDeviceThunk from 'redux-store/thunks/devices/deleteDeviceThunk';

interface Props extends DefaultPopupProps {
  serials: DeviceSerial[] | null;
}

const RemoveMultipleDevicesModal: FC<Props> = ({ onClose, serials, open }) => {
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { t } = useTranslation();

  const recreatedPath = formatDynamicRoute(router.asPath, router.query);

  const [, setDeviceStates] = useState<Record<string, string | boolean | null>>(
    {},
  );

  const removeDevices = useCallback(async () => {
    if (!serials?.length) return;

    // set all to false
    setDeviceStates(
      serials.reduce((acc, serial) => ({ ...acc, [serial]: null }), {}),
    );

    const handleDeleteDevice = async (
      serial: DeviceSerial,
    ): Promise<boolean> => {
      try {
        setDeviceStates(prev => ({ ...prev, [serial]: false }));
        const result = await dispatch(deleteDeviceThunk(serial)).unwrap();

        if (!result.success) {
          setDeviceStates(prev => ({
            ...prev,
            [serial]: t('common:pages.settings.remove_device_error'),
          }));
        } else {
          setDeviceStates(prev => ({ ...prev, [serial]: true }));

          dispatch(removeDeviceOverviewSelectedDevice(serial));

          return true;
        }
      } catch (e) {
        console.error('error deleting device', e);
        setDeviceStates(prev => ({
          ...prev,
          [serial]: t('common:pages.settings.remove_device_error'),
        }));
      }

      return false;
    };

    setLoading(true);

    let deleteSuccess = true;

    for await (const serial of serials) {
      if (!(await handleDeleteDevice(serial))) {
        deleteSuccess = false;
      }
    }

    if (deleteSuccess) {
      setSuccess(true);

      setError(null);

      setTimeout(async () => {
        await router.replace(recreatedPath);
        onClose();
        setSuccess(false);
      }, 1000);
    } else {
      setError(t('common:pages.settings.remove_device_error'));
    }

    setLoading(false);
  }, [serials, dispatch, t, onClose, router, recreatedPath]);

  return (
    <Modal open={open} onClose={onClose} hideCloseButton={success}>
      {!success ? (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{ overflowY: 'auto' }}
        >
          <Box mb={4}>
            <Typography variant="h2" mb={2} textAlign="center">
              {t('common:pages.settings.remove_multiple_devices')}
            </Typography>
            <Typography variant="body1" textAlign="center">
              {t('common:pages.settings.remove_multiple_devices_description')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" mt={3} gap={1} width="100%">
            <Button
              onClick={onClose}
              color="neutral"
              fullWidth
              variant="flat"
              size="large"
              data-testid="cancel-button"
            >
              {t('common:cancel')}
            </Button>
            <Button
              disabled={isLoading}
              onClick={removeDevices}
              fullWidth
              variant="flat"
              size="large"
              color="error"
              data-testid="submit"
            >
              <CustomTrashIcon sx={{ mr: 1 }} />
              {t('common:pages.settings.deleteMultipleDevicesButton')}
            </Button>
          </Box>
          <ErrorText error={error} mt={2} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            margin="50px 20px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <SuccessIcon width={100} height={100} />
            <Typography
              variant="h1"
              margin="20px 10px"
              data-testid="success-text"
            >
              {t('common:pages.settings.multiple_devices_removed')}
            </Typography>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default RemoveMultipleDevicesModal;
