import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useCallback, useState } from 'react';
import type { FC } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import CustomTrashIcon from 'components/Icons/CustomTrashIcon';
import SuccessIcon from 'components/Icons/SuccessIcon';
import Modal from 'components/Modal';

import { useAppDispatch } from 'redux-store';
import deleteOrganizationThunk from 'redux-store/thunks/organizations/deleteOrganizationThunk';
import getAllOrganizationsThunk from 'redux-store/thunks/organizations/getAllOrganizationsThunk';

interface Props extends DefaultPopupProps {
  organizationId: number | null;
}

const RemoveOrganizationModal: FC<Props> = ({
  onClose,
  organizationId,
  open,
}) => {
  const [error, setError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { t } = useTranslation();

  const removeOrganization = useCallback(async () => {
    if (!organizationId) return;

    try {
      setLoading(true);
      const result = await dispatch(
        deleteOrganizationThunk(organizationId),
      ).unwrap();

      if (!result.success) {
        setError(
          t('common:pages.organization.errors.remove_organization_error'),
        );
      } else {
        setSuccess(true);
        setError(null);
        setTimeout(async () => {
          await dispatch(getAllOrganizationsThunk({})).unwrap();
          onClose();
          setSuccess(false);
        }, 1000);
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch, onClose, organizationId, t]);

  return (
    <Modal open={open} onClose={onClose} hideCloseButton={success}>
      {!success ? (
        <Box height="100%" sx={{ overflowY: 'auto' }}>
          <Box mb={4}>
            <Typography
              variant="h2"
              textAlign="center"
              mb={2}
              data-testid="remove-organization-modal"
            >
              {t('common:pages.organization.remove_organization.title')}
            </Typography>
            <Typography variant="body1" textAlign="center">
              {t('common:pages.organization.remove_organization.description')}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap={1} width="100%">
            <Button
              onClick={onClose}
              color="neutral"
              fullWidth
              variant="flat"
              size="large"
              data-testid="cancel-button"
            >
              {t('common:cancel')}
            </Button>
            <Button
              disabled={isLoading}
              onClick={removeOrganization}
              startIcon={<CustomTrashIcon />}
              fullWidth
              size="large"
              variant="flat"
              color="error"
              data-testid="submit"
            >
              {t('common:remove')}
            </Button>
          </Box>
          <ErrorText error={error} mt={2} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box
            margin="50px 20px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <SuccessIcon width={100} height={100} />
            <Typography
              variant="h1"
              margin="20px 10px"
              data-testid="success-text"
            >
              {t('common:pages.organization.remove_organization.success')}
            </Typography>
          </Box>
        </Box>
      )}
    </Modal>
  );
};

export default RemoveOrganizationModal;
