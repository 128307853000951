import { Box, Typography, styled } from '@mui/material';
import { useTranslation } from 'next-i18next';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';
import type { FC } from 'react';

import LanguageSelectorButton from 'components/LanguageSelectorButton';
import Modal from 'components/Modal';
import ThemeSwitcher from 'components/ThemeSwitcher';

import { useAppDispatch, useAppSelector } from 'redux-store';
import { setPreferencesModal } from 'redux-store/slices/ui';

interface CustomButtonBoxProps {
  height?: string | number;
}

const { publicRuntimeConfig } = getConfig();
const {
  app: { buildInfo },
} = publicRuntimeConfig;

const CustomButtonBox = styled(Box)<CustomButtonBoxProps>(
  ({ theme, height }) => ({
    cursor: 'pointer',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    height: height ? height : '48px',
    width: '100%',
    padding: '12px 16px',
    background: theme.vars.palette.grey[200],
    ...theme.applyStyles('dark', {
      background: theme.vars.palette.grey[800],
    }),
    '&:hover': {
      opacity: 0.7,
    },
  }),
);

const PreferencesModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();

  /*
  const themeMode = useAppSelector(state => state.ui.darkMode);

  const changeThemeMode = (newThemeMode: DarkMode): void => {
    dispatch(setDarkMode(newThemeMode));
  };
  */

  const closeModal = useCallback(() => {
    dispatch(setPreferencesModal(false));
  }, [dispatch]);

  const openPreferencesModal = useAppSelector(
    state => state.ui.preferencesModal,
  );

  const visitLicenses = useCallback(async () => {
    await router.push('/licenses');
    closeModal();
  }, [closeModal, router]);

  return (
    <Modal open={openPreferencesModal} onClose={() => closeModal()}>
      <Box mb={4}>
        <Typography variant="h2" textAlign="center" mb={2}>
          {t('common:pages.preferences.page_title')}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="center"
      >
        <Box mb={5} width="100%">
          <Typography variant="h3" mb={2}>
            {t('common:language')}
          </Typography>
          <LanguageSelectorButton />
        </Box>
        <Box mb={5} width="100%">
          <Typography variant="h3" mb={2}>
            {t('common:licenses')}
          </Typography>
          <Box onClick={visitLicenses}>
            <CustomButtonBox display="flex" alignItems="center">
              <Typography variant="body1">
                {t('common:view_licenses')}
              </Typography>
            </CustomButtonBox>
          </Box>
        </Box>
        {/* Comment out dark mode switch until we have proper dark theme */}
        {process.env.SHOW_THEME_SELECTOR === 'true' ? (
          <Box mb={5}>
            <Typography variant="h3" mb={2}>
              {t('common:theme')}
            </Typography>
            <ThemeSwitcher />
          </Box>
        ) : null}
        <Box maxWidth="100%">
          <Typography
            variant="body1"
            color="grey.500"
            fontSize="10px"
            sx={{ userSelect: 'none' }}
          >
            {buildInfo}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default PreferencesModal;
