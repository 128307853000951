import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import {
  Box,
  IconButton,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import Fuse from 'fuse.js';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useDebounce } from 'use-debounce';

import Button from 'components/Button';
import CustomSearchbar from 'components/CustomSearchBar';
import ErrorText from 'components/ErrorText';

import { useAppDispatch, useAppSelector } from 'redux-store';
import {
  setAddMemberToOrganizationModal,
  setAssignMembersToDevicesModal,
} from 'redux-store/slices/ui';
import assignMembersToDeviceThunk from 'redux-store/thunks/organizationOverview/assignMembersToDeviceThunk';
import getOrganizationByIdThunk from 'redux-store/thunks/organizations/getOrganizationByIdThunk';

import type { AssignMembersToDevicesModalStageProps } from '..';
import MemberList from './MemberList';

const AssignMembersToDevices: FC<AssignMembersToDevicesModalStageProps> = ({
  state,
  setState,
  onCloseProxy,
  onClose,
  organizationId,
  deviceId,
  userId,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [searchString, setSearchString] = useState('');
  const [searchResults, setSearchResults] = useState<string[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const organizationMembers = useAppSelector(
    s => s.organizations.selectedOrganization.organization?.users ?? [],
  );

  const fuse = useMemo(
    () =>
      new Fuse(organizationMembers, {
        keys: ['email'],
        threshold: 0.4,
      }),
    [organizationMembers],
  );

  const handleSearch = useCallback((): void => {
    if (!searchString) {
      setSearchResults(null);

      return;
    }

    const results = fuse.search(searchString);

    setSearchResults(results.map(result => result.item.email));
  }, [fuse, searchString]);

  const [debouncedSearch] = useDebounce(searchString, 500);

  useEffect(() => {
    if (debouncedSearch) {
      handleSearch();
    }
  }, [debouncedSearch, handleSearch]);

  const handleClearSearch = useCallback((): void => {
    setSearchString('');
    setSearchResults(null);
  }, []);

  const selectedMemberId = useMemo(() => state.members, [state.members]);
  const setSelectedMemberId = useCallback(
    (members: number[]) => {
      setState(prev => ({ ...prev, members }));
    },
    [setState],
  );

  const filteredMembers = useMemo(
    () =>
      searchResults
        ? organizationMembers.filter(member =>
            searchResults.includes(member.email),
          )
        : organizationMembers,
    [organizationMembers, searchResults],
  );

  const handleAssignMembersToDevice = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      if (!organizationId || !deviceId) return;

      try {
        setLoading(true);

        const result = await dispatch(
          assignMembersToDeviceThunk({
            organizationId: organizationId,
            deviceId: deviceId,
            data: {
              users: selectedMemberId,
            },
          }),
        ).unwrap();

        if (result.success) {
          await dispatch(
            getOrganizationByIdThunk({ organizationId: organizationId }),
          ).unwrap();

          setState({ members: [], stage: 'success' });
          setTimeout(() => {
            setState({ members: [], stage: 'members' });
            onClose();
          }, 1250);
        } else {
          setError('Error'); //  to be defined for each device
        }
      } catch (e) {
        Sentry.captureException(e, {
          tags: { page: 'AssignDevicesToMembersModal' },
        });
      } finally {
        setLoading(false);
      }
    },
    [deviceId, dispatch, onClose, organizationId, selectedMemberId, setState],
  );

  const openAddMemberModal = useCallback(() => {
    dispatch(
      setAssignMembersToDevicesModal({
        isOpen: false,
        organizationId: null,
        deviceId: null,
        userId: null,
      }),
    );
    dispatch(
      setAddMemberToOrganizationModal({
        isOpen: true,
        organizationId: organizationId,
        userId: userId,
      }),
    );
  }, [dispatch, organizationId, userId]);

  return (
    <>
      <Box
        sx={{
          height: '45vh',
          overflow: 'auto',
          paddingBottom: 5,
          width: '100%',
        }}
      >
        <Box mb={4}>
          <Typography variant="h2" mb={2} textAlign="center">
            {t(
              'common:pages.organization_overview.assign_members_to_device.title',
            )}
          </Typography>
        </Box>
        <Box mb={4} width="100%">
          <ListItemButton
            sx={{
              backgroundColor: theme.vars.palette.grey[100],
              borderRadius: 2,
            }}
            onClick={openAddMemberModal} // Add member is optional
            // onClick={() => setState(prev => ({ ...prev, stage: 'addMembers' }))} // to be updated
          >
            <ListItemText
              color="primary"
              primaryTypographyProps={{
                style: {
                  color: theme.vars.palette.primary.dark,
                  fontWeight: 500,
                },
              }}
              primary={t(
                'common:pages.organization_overview.assign_members_to_device.add_member',
              )}
            />
            <IconButton edge="end" disableRipple>
              <AddCircleOutlineRoundedIcon color="primary" />
            </IconButton>
          </ListItemButton>
        </Box>
        <Typography variant="h3" mb={1}>
          {t(
            'common:pages.organization_overview.assign_members_to_device.members',
          )}
        </Typography>
        <Box>
          <Box mb={3} width="100%">
            <CustomSearchbar
              search={searchString}
              setSearch={setSearchString}
              hasSearched={searchString.length > 0}
              handleSearch={handleSearch}
              handleClearSearch={handleClearSearch}
            />
          </Box>
          {filteredMembers.length > 0 ? (
            <Box>
              {filteredMembers.map(item => (
                <MemberList
                  key={item.email}
                  member={item}
                  organizationId={organizationId}
                  deviceId={deviceId}
                  userId={null}
                  selectedMembers={selectedMemberId}
                  setSelectedMembers={setSelectedMemberId}
                />
              ))}
            </Box>
          ) : (
            <Typography variant="body1" textAlign="center">
              {t(
                'common:pages.organization_overview.add_user.no_devices_found',
              )}
            </Typography>
          )}
          {/* to add error for each response */}
          {/* {error?.map(e => (
            <ErrorText key={e.serial} error={`SN ${e.serial} : ${e.error}`} />
          ))} */}
          <ErrorText error={error} />
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={2}
        gap={2}
      >
        <Button
          variant="flat"
          color="neutral"
          size="large"
          fullWidth
          onClick={onCloseProxy}
          data-testid="modal-cancel-button"
        >
          {t('common:cancel')}
        </Button>
        <Button
          variant="flat"
          size="large"
          fullWidth
          disabled={isLoading}
          onClick={handleAssignMembersToDevice}
          data-testid="modal-save-button"
        >
          {t('common:save')}
        </Button>
      </Box>
    </>
  );
};

export default AssignMembersToDevices;
