import type { ChargingSessionData } from 'redux-store/slices/api/types';
import type { DeviceGroupId } from 'redux-store/slices/deviceGroups/types';
import type {
  DeviceSerial,
  SettingsTab,
  UIStateType,
} from 'redux-store/slices/ui/types';

export type SettingsPageUrl =
  | {
      href: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      asPath: (id: DeviceSerial, args: any) => string;
    }
  | string;

export type SettingsPageUrls = {
  subpages?: {
    [key: string]: SettingsPageUrls;
  };
} & {
  [key: string]: SettingsPageUrl;
};

export type RecursiveSettingsPageUrls =
  | SettingsPageUrls
  | {
      [key: string]: RecursiveSettingsPageUrls;
    };

export type GenericPageUrls = string | ((...args: never[]) => string);

export type RecursiveGenericPageUrls =
  | GenericPageUrls
  | {
      [key: string]: RecursiveGenericPageUrls;
    };

export interface PageUrls {
  settings: {
    [key: string]: RecursiveSettingsPageUrls;
  };
  [key: string]: string | RecursiveGenericPageUrls;
}

const pageUrls = {
  settings: {
    charging: {
      nextTrip: {
        href: '/[id]/settings/charging/next-trip',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/charging/next-trip`;
        },
      },
      limit: {
        href: '/[id]/settings/charging/limit',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/charging/limit`;
        },
      },
      current: {
        href: '/[id]/settings/charging/current',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/charging/current`;
        },
        subpages: {
          editCurrentLevels: {
            href: '/[id]/settings/charging/current/edit-current-levels',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/charging/current/edit-current-levels`;
            },
          },
        },
      },
      scheduler: {
        href: '/[id]/settings/charging/scheduler',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/charging/scheduler`;
        },
        subpages: {
          editSchedule: {
            href: '/[id]/settings/charging/scheduler/[scheduleId]',
            asPath: (
              id: DeviceSerial,
              { scheduleId }: { scheduleId: string },
            ): string => {
              return `/${id}/settings/charging/scheduler/${scheduleId}`;
            },
          },
        },
      },
      loadBalancing: {
        href: '/[id]/settings/charging/load-balancing',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/charging/load-balancing`;
        },
        subpages: {
          phaseAssignments: {
            href: '/[id]/settings/charging/load-balancing/phase-assignments',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/charging/load-balancing/phase-assignments`;
            },
          },
          overview: {
            href: '/[id]/settings/charging/load-balancing/overview',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/charging/load-balancing/overview`;
            },
          },
        },
      },
      carSettings: {
        href: '/[id]/settings/charging/car',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/charging/car`;
        },
        subpages: {
          chargingMode: {
            href: '/[id]/settings/charging/car/charging-mode',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/charging/car/charging-mode`;
            },
          },
        },
      },
    },
    eco: {
      flexiblePrice: {
        href: '/[id]/settings/eco/flexible-price',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/eco/flexible-price`;
        },
      },
      pvSurplus: {
        href: '/[id]/settings/eco/pv-surplus',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/eco/pv-surplus`;
        },
        subpages: {
          gridPreference: {
            href: '/[id]/settings/eco/pv-surplus/grid-preference',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/eco/pv-surplus/grid-preference`;
            },
          },
          preferredPhase: {
            href: '/[id]/settings/eco/pv-surplus/preferred-phase',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/eco/pv-surplus/preferred-phase`;
            },
          },
        },
      },
    },
    controllerSensors: {
      sensors: {
        href: '/[id]/settings/controller-sensors/sensors',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/controller-sensors/sensors`;
        },
        subpages: {
          voltageSensor: {
            href: '/[id]/settings/controller-sensors/sensors/voltage-sensor/[phase]',
            asPath: (
              id: DeviceSerial,
              { phase }: { phase: string },
            ): string => {
              return `/${id}/settings/controller-sensors/sensors/voltage-sensor/${phase}`;
            },
          },
          currentSensor: {
            href: '/[id]/settings/controller-sensors/sensors/current-sensor/[sensorID]',
            asPath: (
              id: DeviceSerial,
              { sensorID }: { sensorID: string },
            ): string => {
              return `/${id}/settings/controller-sensors/sensors/current-sensor/${sensorID}`;
            },
          },
          mecMeter: {
            href: '/[id]/settings/controller-sensors/sensors/mec-meter',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/controller-sensors/sensors/mec-meter`;
            },
          },
        },
      },
      categories: {
        href: '/[id]/settings/controller-sensors/categories',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/controller-sensors/categories`;
        },
        subpages: {
          manageCustomCategories: {
            href: '/[id]/settings/controller-sensors/categories/manage',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/controller-sensors/categories/manage`;
            },
          },
          infos: {
            href: '/[id]/settings/controller-sensors/categories/infos/[categoryIndex]',
            asPath: (
              id: DeviceSerial,
              { categoryIndex }: { categoryIndex: string },
            ): string => {
              return `/${id}/settings/controller-sensors/categories/infos/${categoryIndex}`;
            },
            subpages: {
              icon: {
                href: '/[id]/settings/controller-sensors/categories/infos/[categoryIndex]/icon',
                asPath: (
                  id: DeviceSerial,
                  { categoryIndex }: { categoryIndex: string },
                ): string => {
                  return `/${id}/settings/controller-sensors/categories/infos/${categoryIndex}/icon`;
                },
              },
            },
          },
        },
      },
    },
    security: {
      cableLock: {
        href: '/[id]/settings/security/cable-lock',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/security/cable-lock`;
        },
      },
      accessControl: {
        href: '/[id]/settings/security/access-control',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/security/access-control`;
        },
        subpages: {
          rfid: {
            href: '/[id]/settings/security/access-control/rfid',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/security/access-control/rfid`;
            },
            subpages: {
              rfidCard: {
                href: '/[id]/settings/security/access-control/rfid/rfid-card/[rfid]',
                asPath: (
                  id: DeviceSerial,
                  { rfid }: { rfid: string },
                ): string => {
                  return `/${id}/settings/security/access-control/rfid/rfid-card/${rfid}`;
                },
              },
            },
          },
        },
      },
      password: {
        href: '/[id]/settings/security/password',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/security/password`;
        },
      },
      technicianPassword: {
        href: '/[id]/settings/security/technician-password',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/security/technician-password`;
        },
      },
      country: {
        href: '/[id]/settings/security/country',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/security/country`;
        },
      },
      grid: {
        href: '/[id]/settings/security/grid',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/security/grid`;
        },
      },
      groundCheck: {
        href: '/[id]/settings/security/ground-check',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/security/ground-check`;
        },
      },
      digitalInput: {
        href: '/[id]/settings/security/digital-input',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/security/digital-input`;
        },
      },
    },
    connectivity: {
      wifi: {
        href: '/[id]/settings/connectivity/wifi',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/connectivity/wifi`;
        },
        subpages: {
          scanResult: {
            href: '/[id]/settings/connectivity/wifi/scan/[networkSsid]',
            asPath: (
              id: DeviceSerial,
              { networkSsid }: { networkSsid: string },
            ): string => {
              return `/${id}/settings/connectivity/wifi/scan/${encodeURIComponent(
                networkSsid,
              )}`;
            },
          },
          configure: {
            href: '/[id]/settings/connectivity/wifi/config/[index]',
            asPath: (
              id: DeviceSerial,
              { index }: { index: string },
            ): string => {
              return `/${id}/settings/connectivity/wifi/config/${index}`;
            },
          },
        },
      },
      ethernet: {
        href: '/[id]/settings/connectivity/ethernet',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/connectivity/ethernet`;
        },
      },
      cellular: {
        href: '/[id]/settings/connectivity/cellular',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/connectivity/cellular`;
        },
        subpages: {
          authType: {
            href: '/[id]/settings/connectivity/cellular/auth-type',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/connectivity/cellular/auth-type`;
            },
          },
        },
      },
      hotspot: {
        href: '/[id]/settings/connectivity/hotspot',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/connectivity/hotspot`;
        },
        subpages: {
          channel: {
            href: '/[id]/settings/connectivity/hotspot/channel',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/connectivity/hotspot/channel`;
            },
          },
        },
      },
      apiSettings: {
        href: '/[id]/settings/connectivity/api-settings',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/connectivity/api-settings`;
        },
      },
      ocpp: {
        href: '/[id]/settings/connectivity/ocpp',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/connectivity/ocpp`;
        },
        subpages: {
          status: {
            href: '/[id]/settings/connectivity/ocpp/status',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/connectivity/ocpp/status`;
            },
          },
          dummyCard: {
            href: '/[id]/settings/connectivity/ocpp/dummy-card',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/connectivity/ocpp/dummy-card`;
            },
          },
          networkSecurity: {
            href: '/[id]/settings/connectivity/ocpp/network-security',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/connectivity/ocpp/network-security`;
            },
          },
          fallbackMode: {
            href: '/[id]/settings/connectivity/ocpp/fallback-mode',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/connectivity/ocpp/fallback-mode`;
            },
          },
          advanced: {
            href: '/[id]/settings/connectivity/ocpp/advanced',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/connectivity/ocpp/advanced`;
            },
          },
        },
      },
    },
    general: {
      deviceName: {
        href: '/[id]/settings/general/device-name',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/general/device-name`;
        },
      },
      timeAndDate: {
        href: '/[id]/settings/general/time-and-date',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/general/time-and-date`;
        },
        subpages: {
          changeTimezone: {
            href: '/[id]/settings/general/time-and-date/change-timezone',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/general/time-and-date/change-timezone`;
            },
          },
          ntpServer: {
            href: '/[id]/settings/general/time-and-date/ntp-server',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/general/time-and-date/ntp-server`;
            },
          },
        },
      },
      led: {
        href: '/[id]/settings/general/led',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/general/led`;
        },
        subpages: {
          readyState: {
            href: '/[id]/settings/general/led/ready-state',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/general/led/ready-state`;
            },
          },
          chargingState: {
            href: '/[id]/settings/general/led/charging-state',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/general/led/charging-state`;
            },
          },
          finishedState: {
            href: '/[id]/settings/general/led/finished-state',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/general/led/finished-state`;
            },
          },
          waitingState: {
            href: '/[id]/settings/general/led/waiting-state',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/general/led/waiting-state`;
            },
          },
        },
      },
      controllerPairing: {
        href: '/[id]/settings/general/controller-pairing',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/general/controller-pairing`;
        },
        subpages: {
          controllerInfo: {
            href: '/[id]/settings/general/controller-pairing/[controllerSerial]',
            asPath: (
              id: DeviceSerial,
              { controllerSerial }: { controllerSerial: DeviceSerial },
            ): string => {
              return `/${id}/settings/general/controller-pairing/${controllerSerial}`;
            },
          },
        },
      },
      controllerDisplay: {
        href: '/[id]/settings/general/controller-display',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/general/controller-display`;
        },
        subpages: {
          language: {
            href: '/[id]/settings/general/controller-display/language',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/general/controller-display/language`;
            },
          },
          timeout: {
            href: '/[id]/settings/general/controller-display/timeout',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/general/controller-display/timeout`;
            },
          },
        },
      },
    },
    about: {
      deviceInformation: {
        href: '/[id]/settings/about/device-information',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/about/device-information`;
        },
      },
      firmwareInformation: {
        href: '/[id]/settings/about/firmware-information',
        asPath: (id: DeviceSerial): string => {
          return `/${id}/settings/about/firmware-information`;
        },
        subpages: {
          changelog: {
            href: '/[id]/settings/about/firmware-information/changelog',
            asPath: (id: DeviceSerial): string => {
              return `/${id}/settings/about/firmware-information/changelog`;
            },
          },
        },
      },
    },
  },
  homeDashboard: '/',
  register: '/user/register',
  login: '/user/login',
  profile: '/user/profile',
  settingsPage: (id: DeviceSerial): string => {
    return `/${id}/settings`;
  },
  settingsPageGeneric: '/[id]/settings',
  settingsPageWithCategory: (
    id: DeviceSerial,
    category: SettingsTab,
  ): string => {
    return `/${id}/settings/${category}`;
  },
  settingsPageWithCategoryGeneric: '/[id]/settings/[category]',
  device: {
    devicePage: (id: DeviceSerial): string => {
      return `/${id}`;
    },
    devicePageGeneric: '/[id]',
    exportPage: (id: DeviceSerial): string => {
      return `/${id}/export`;
    },
    exportPageGeneric: '/[id]/export',
  },
  devices: {
    all: '/devices',
    specificDeviceType: (
      deviceType: UIStateType['deviceOverview']['deviceTypeFilter'],
    ): string => {
      return `/devices?deviceType=${deviceType}`;
    },
    specificDeviceTypeGeneric: '/devices?deviceType=[deviceType]',
  },
  energyReport: {
    energyReportPage: (type: string, ids: DeviceSerial[]): string => {
      return `/energy-report?deviceType=${type}&selectedDevices=${ids.join(
        ',',
      )}`;
    },
  },
  energyReportGraphs: {
    energyReportGraphPage: (data?: ChargingSessionData): string => {
      const baseUrl = `/energy-report-graphs`;

      if (data) {
        const dataQuery = new URLSearchParams({
          session: data.session_identifier,
          data: JSON.stringify(data),
        }).toString();

        return `${baseUrl}?${dataQuery}`;
      }

      return baseUrl;
    },
  },
  deviceGroups: {
    all: '/device-groups',
  },
  deviceGroup: {
    all: (id: DeviceGroupId): string => `/device-group/${id}`,
    allGeneric: '/device-group/[id]',
    deviceGroupPage: (id: DeviceGroupId): string => {
      return `/device-group/${id}`;
    },
    deviceGroupPageGeneric: '/device-group/[id]',
    specificDeviceType: (
      id: DeviceGroupId,
      deviceType: UIStateType['deviceGroup']['deviceTypeFilter'],
    ): string => {
      return `/device-group/${id}?deviceType=${deviceType}`;
    },
    specificDeviceTypeGeneric: '/device-group/[id]?deviceType=[deviceType]',
    reportPage: (id: number): string => {
      return `/device-group/${id}/report`;
    },
    reportPageGeneric: '/device-group/[id]/report',
  },
  deviceLocations: {
    all: '/device-locations',
  },
  deviceLocation: {
    deviceLocationPage: (id: number): string => {
      return `/device-location/${id}`;
    },
    deviceLocationPageGeneric: '/device-location/[id]',
  },
  rfid: {
    rfidPage: (rfid: string): string => {
      return `/rfid/${rfid}`;
    },
    rfidPageGeneric: '/rfid/[id]',
  },
  rfids: {
    all: '/rfids',
  },
  rfidGroups: {
    all: '/rfid-groups',
  },
  rfidLocations: {
    all: '/rfid-locations',
  },
  rfidLocation: {
    rfidLocationPage: (id: number): string => {
      return `/rfid-location/${id}`;
    },
    rfidLocationPageGeneric: '/rfid-location/[id]',
  },
  rfidGroup: {
    rfidGroupPage: (id: number): string => {
      return `/rfid-group/${id}`;
    },
    rfidGroupPageGeneric: '/rfid-group/[id]',
  },
  setupDevices: '/setup-devices',
  preferences: '/preferences',
  help: '/help-and-support',
  installerCodes: '/installer-codes',
  admin: {
    organizations: '/admin/organizations',
    adminOrganizationPage: (id: number): string => {
      return `/admin/organization/${id}`;
    },
  },
} as const;

export default pageUrls;
