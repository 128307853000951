import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

import SuccessIcon from 'components/Icons/SuccessIcon';

const AddUserSuccess: FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      sx={{ overflowY: 'auto' }}
    >
      <Box
        margin="50px 20px"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <SuccessIcon width={100} height={100} />
        <Typography
          variant="h1"
          margin="20px 10px"
          textAlign="center"
          data-testid="success-text"
        >
          {t('common:pages.organization_overview.add_user.success')}
        </Typography>
      </Box>
    </Box>
  );
};

export default AddUserSuccess;
