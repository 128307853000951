import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Box } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Dispatch, FC, SetStateAction } from 'react';
import type { DefaultPopupProps } from 'utils/props';

import Modal from 'components/Modal';

import AssignMemberSuccess from './AssignMemberSuccess';
import AssignMembersToDevice from './AssignMembersToDevices';

type AssignMembersToDevicesStage = 'members' | 'success';

interface AssignMembersToDevicesModalState {
  stage: AssignMembersToDevicesStage;
  members: number[];
}

export interface AssignMembersToDevicesModalStageProps {
  open: boolean;
  state: AssignMembersToDevicesModalState;
  setState: Dispatch<SetStateAction<AssignMembersToDevicesModalState>>;
  onClose: () => void;
  onCloseProxy: () => void;
  organizationId: number | null;
  userId: number | null;
  deviceId: number | null;
  selectedMembers: number[];
}

const StageComponents: Record<
  AssignMembersToDevicesStage,
  FC<AssignMembersToDevicesModalStageProps>
> = {
  members: AssignMembersToDevice,
  success: AssignMemberSuccess,
};

interface Props extends DefaultPopupProps {
  organizationId: number | null;
  deviceId: number | null;
  userId: number | null;
  selectedMembers: number[];
}

const AssignMembersToDevicesModal: FC<Props> = ({
  onClose,
  open,
  organizationId,
  deviceId,
  userId,
  selectedMembers,
}) => {
  const [state, setState] = useState<AssignMembersToDevicesModalState>({
    stage: 'members',
    members: [],
  });

  useEffect(() => {
    if (!open) {
      setState(prev => ({ ...prev, members: [] }));
    }
  }, [open]);

  const StageComponent = useMemo(() => StageComponents[state.stage], [state]);

  const onCloseProxy = useCallback((): void => {
    if (state.stage === 'members') {
      setState(prev => ({
        ...prev,
        members: [],
      }));

      onClose();
    }
  }, [onClose, state]);

  return (
    <Modal
      open={open}
      onClose={onCloseProxy}
      overrideCloseButtonIcon={
        state.stage !== 'members' ? <ArrowBackRoundedIcon /> : undefined
      }
      hideCloseButton={state.stage === 'success'}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        sx={{ overflowY: 'auto' }}
      >
        <StageComponent
          open={open}
          setState={setState}
          state={state}
          onClose={onClose}
          onCloseProxy={onCloseProxy}
          organizationId={organizationId}
          deviceId={deviceId}
          userId={userId}
          selectedMembers={selectedMembers}
        />
      </Box>
    </Modal>
  );
};

export default AssignMembersToDevicesModal;
