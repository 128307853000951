import { Box, useTheme, Typography } from '@mui/material';
import AddButton from 'content/pages/OrganizationOverviewPage/components/AddButton';
import { useMemo } from 'react';
import type { FC } from 'react';
import {
  deviceIsCharger,
  deviceIsController,
} from 'utils/common/deviceTypeUtils';

import GoeControllerOutlinedIcon from 'components/Icons/DeviceIcons/GoeControllerOutlinedIcon';
import DevicesIcon from 'components/Icons/DevicesIcon';

import { useAppSelector } from 'redux-store';

export interface DeviceTypeCountProps {
  devices: number[] | undefined;
  openAssignDevicesModal: () => void;
}

const DeviceTypeCount: FC<DeviceTypeCountProps> = ({
  devices,
  openAssignDevicesModal,
}) => {
  const theme = useTheme();

  const deviceList = useAppSelector(state => state.devices.items);

  const filteredDevices = useMemo(() => {
    return deviceList.filter(device => (devices || []).includes(device.id));
  }, [deviceList, devices]);

  const chargersCount = filteredDevices.filter(device =>
    deviceIsCharger(device.type),
  ).length;

  const controllersCount = filteredDevices.filter(device =>
    deviceIsController(device.type),
  ).length;

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {chargersCount ? (
        <Box display="flex" alignItems="center" gap={0.5}>
          <DevicesIcon color="primary" />
          <Typography color="primary">{chargersCount}</Typography>
        </Box>
      ) : null}

      {controllersCount ? (
        <Box display="flex" alignItems="center" gap={0.5}>
          <GoeControllerOutlinedIcon color={theme.vars.palette.primary.main} />
          <Typography color="primary">{controllersCount}</Typography>
        </Box>
      ) : null}
      {!chargersCount && !controllersCount ? (
        <AddButton handleClick={openAssignDevicesModal} />
      ) : null}
    </Box>
  );
};

export default DeviceTypeCount;
