import { Box, IconButton } from '@mui/material';
import Image from 'next/image';
import type { FC } from 'react';
import React from 'react';

import files from 'constants/files';

import SideBarIcon from 'components/Icons/SideBarIcon';

interface NavbarProps {
  isSidebarOpen: boolean;
  handleToggleSidebar: () => void;
}
const Navbar: FC<NavbarProps> = ({ isSidebarOpen, handleToggleSidebar }) => {
  return (
    <Box>
      <Box
        display="flex"
        sx={{
          backgroundColor: 'grey.100',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          height: 80,
          mb: 2,
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            left: 20,
            width: 56,
            height: 48,
            borderRadius: 2,
          }}
          onClick={handleToggleSidebar}
        >
          <SideBarIcon isOpen={isSidebarOpen} sx={{ color: 'grey.500' }} />
        </IconButton>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Image
            src={files.logo.normal}
            alt="go-e Logo"
            width={65}
            height={32}
            priority
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
