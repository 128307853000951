import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import {
  Box,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useState } from 'react';
import type { FC } from 'react';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import CustomListItemButton from 'components/MuiCustom/ListItemButton';

import { useAppDispatch } from 'redux-store';
import inviteMemberToOrganizationThunk from 'redux-store/thunks/organizationOverview/inviteMemberToOrganizationThunk';
import getAllOrganizationsThunk from 'redux-store/thunks/organizations/getAllOrganizationsThunk';
import getOrganizationByIdThunk from 'redux-store/thunks/organizations/getOrganizationByIdThunk';

import type { AddMemberToOrganizationModalStageProps } from '..';

const AssignRoleToUser: FC<AddMemberToOrganizationModalStageProps> = ({
  state,
  organizationId,
  setState,
  onCloseProxy,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const userRoles = [
    {
      role: t('common:pages.organization_overview.add_user.role_user'),
      info: t('common:pages.organization_overview.add_user.role_user_info'),
      value: 'member',
    },
    {
      role: t('common:pages.organization_overview.add_user.role_admin'),
      info: t('common:pages.organization_overview.add_user.role_admin_info'),
      value: 'org_admin',
    },
  ];

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleAssignRole = useCallback(async () => {
    let result = undefined;
    if (!organizationId) return;
    try {
      setLoading(true);

      result = await dispatch(
        inviteMemberToOrganizationThunk({
          organizationId: organizationId,
          data: {
            email: state.email,
            role: state.role,
          },
        }),
      ).unwrap();

      if (!result.success) {
        setError(result.error);
        setLoading(false);

        return;
      }

      setError(null);

      setState({
        email: '',
        role: '',
        devices: [],
        newUserID: result.user_id,
        stage: 'devices',
      });

      await dispatch(getAllOrganizationsThunk({})).unwrap();
      await dispatch(
        getOrganizationByIdThunk({
          organizationId: organizationId,
        }),
      ).unwrap();

      setTimeout(() => {
        setState(prev => ({
          ...prev,
          stage: 'devices',
        }));
      }, 1250);
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          state,
          result,
        },
      });
    } finally {
      setLoading(false);
    }
  }, [dispatch, organizationId, setState, state]);

  return (
    <>
      <Box mb={4}>
        <Typography variant="h2" mb={2} textAlign="center">
          {t('common:pages.organization_overview.add_user.select_role')}
        </Typography>
        <Typography variant="body1" textAlign="center">
          {t('common:pages.organization_overview.add_user.select_role_desc')}
        </Typography>
      </Box>
      <Box mb={4} width="100%" minHeight={150}>
        {userRoles.map((item, index) => {
          const isFirstItem = index === 0;
          const isLastItem = index === userRoles.length - 1;

          return (
            <ListItem disablePadding key={item.role}>
              <CustomListItemButton
                sx={{
                  backgroundColor: 'grey.100',
                  borderBottom: isLastItem
                    ? 0
                    : `1px solid ${theme.vars.palette.grey[300]}`,
                  borderTopLeftRadius: isFirstItem ? 16 : 0,
                  borderTopRightRadius: isFirstItem ? 16 : 0,
                  borderBottomLeftRadius: isLastItem ? 16 : 0,
                  borderBottomRightRadius: isLastItem ? 16 : 0,
                }}
                onClick={() =>
                  setState(prev => ({ ...prev, role: item.value }))
                }
              >
                <Box mr={2} alignItems="center" display="flex">
                  <CheckIcon
                    sx={{
                      color:
                        state.role === item.value
                          ? 'primary.main'
                          : 'transparent',
                    }}
                  />
                </Box>
                <ListItemText primary={item.role} secondary={item.info} />
              </CustomListItemButton>
            </ListItem>
          );
        })}
      </Box>
      <ErrorText error={error} />
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={2}
        gap={2}
      >
        <Button
          variant="flat"
          color="neutral"
          size="large"
          disabled={loading}
          onClick={onCloseProxy}
          startIcon={<KeyboardArrowLeftRoundedIcon />}
          data-testid="modal-cancel-button"
        >
          {t('common:previous')}
        </Button>
        <Button
          variant="flat"
          onClick={handleAssignRole}
          size="large"
          disabled={loading || state.role.length === 0}
          endIcon={<KeyboardArrowRightRoundedIcon />}
          data-testid="modal-save-button"
        >
          {t('common:next')}
        </Button>
      </Box>
    </>
  );
};

export default AssignRoleToUser;
