import type {
  ApiErrorType,
  ApiSuccessType,
  UserManagementApiErrorTypes,
  UserManagementApiSuccessTypes,
} from 'app.types';

import type {
  EnergyReportRequestDataParams,
  EnergyReportResponseType,
  ExportGetStatusResponseType,
  ExportGetTicketResponseType,
  GetChangelogDataQueryParams,
  GetChangelogDataResponse,
  GetDeviceChartsRequestBody,
  GetDeviceChartsResponseType,
  TariffAPIResponse,
} from 'redux-store/slices/api/types';
import type {
  AddDeviceGroupRequestType,
  AddDeviceGroupResponseType,
  AllDeviceGroupsResponseType,
  DeleteDeviceGroupResponseType,
  GetDeviceGroupByIdResponseType,
  MoveDeviceToGroupRequestType,
  MoveDeviceToGroupResponseType,
  AddDeviceToGroupRequestType,
  AddDeviceToGroupResponseType,
  RemoveDeviceFromGroupResponseType,
  RenameDeviceGroupRequestType,
  RenameDeviceGroupResponseType,
} from 'redux-store/slices/deviceGroups/types';
import type {
  AddDeviceRequestBody,
  UpdateDeviceRequestBody,
  GetDeviceByIdResponseType,
  UpdateDeviceResponseType,
  AddDeviceResponseType,
  DeleteDeviceResponseType,
  VerifyDeviceResponseType,
  AllDevicesResponseType,
  AnonymousAddDeviceResponseType,
} from 'redux-store/slices/devices/types';
import type {
  GroupConfigDevicesApiResponse,
  GroupConfigDevicesRequestBody,
} from 'redux-store/slices/groupConfigDevices/types';
import type {
  InviteMemberToOrganizationRequestBody,
  GetMemberByOrganizationIdResponseType,
  GetDevicesByOrganizationIdResponseType,
  AddDeviceToOrganizationRequestBody,
  AssignMembersToDeviceRequestBody,
  AssignDevicesToMemberRequestBody,
  AssignMembersToDeviceResponseType,
  AssignDevicesToMemberResponseType,
  RemoveMemberFromOrgRequestBody,
  RemoveMemberFromOrgResponseType,
  RemoveDeviceFromOrgRequestBody,
  RemoveDeviceFromOrgResponseType,
  DeviceAssignmentsRequestBody,
  DeviceMemberAccessResponseType,
  MemberAssignmentsRequestBody,
} from 'redux-store/slices/organizationOverview/types';
import type {
  AddOrganizationRequestBody,
  AddOrganizationResponseType,
  AllOrganizationsResponseType,
  DeleteOrganizationResponseType,
  GetOrganizationByIdResponseType,
  UpdateOrganizationRequestType,
  UpdateOrganizationResponseType,
} from 'redux-store/slices/organizations/types';
import type {
  GetProfileResponseTypes,
  AuthRequestBody,
} from 'redux-store/slices/profile/types';
import type {
  AddRfidGroupRequestType,
  AddRfidGroupResponseType,
  AllRfidGroupsResponseType,
  DeleteRfidGroupResponseType,
  GetRfidGroupByIdResponseType,
  MoveRfidToGroupRequestType,
  MoveRfidToGroupResponseType,
  AddRfidToGroupRequestType,
  AddRfidToGroupResponseType,
  RemoveRfidFromGroupResponseType,
  RenameRfidGroupResponseType,
  RenameRfidGroupRequestType,
  ImportAnonymousDevicesRequestType,
} from 'redux-store/slices/rfidGroups/types';
import type {
  AddRfidRequestBody,
  AddRfidResponseType,
  AllRfidsResponseType,
  DeleteRfidResponseType,
  GetRfidByIdResponseType,
  UpdateRfidRequestBody,
  UpdateRfidResponseType,
} from 'redux-store/slices/rfids/types';
import type {
  SetupDevicesApiResponse,
  SetupDevicesRequestBody,
} from 'redux-store/slices/setupDevices/types';

type PathParamsType = Record<string, string | number>;

export interface ApiPointsFetchParamsType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: Record<string, any> | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams?: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any;
  urlParams?: PathParamsType;
}

export interface ApiPoint<T extends keyof ApiPointsFetchParamsTypes> {
  path: string;
  getPath: ApiPointsFetchParamsTypes[T]['urlParams'] extends null | undefined
    ? null
    : (params: ApiPointsFetchParamsTypes[T]['urlParams']) => string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
}

export type ApiPointsType = {
  [P in keyof ApiPointsFetchParamsTypes]: ApiPoint<P>;
};

const apiPoints: ApiPointsType = {
  login: {
    path: 'api/user/login',
    getPath: null,
    method: 'POST',
  },
  loginAnonymous: {
    path: 'api/user/loginAnonymous',
    getPath: null,
    method: 'POST',
  },
  register: {
    path: 'api/user/register',
    getPath: null,
    method: 'POST',
  },
  logout: {
    path: 'api/user/logout',
    getPath: null,
    method: 'GET',
  },
  getProfile: {
    path: 'api/user/profile',
    getPath: null,
    method: 'GET',
  },
  setupDevices: {
    path: 'api/devices/settings',
    getPath: null,
    method: 'POST',
  },
  groupConfigDevices: {
    path: 'api/devices/settings',
    getPath: null,
    method: 'POST',
  },
  verifyDevice: {
    path: 'api/devices/verify',
    getPath: null,
    method: 'POST',
  },
  addDevice: {
    path: 'api/devices/add',
    getPath: null,
    method: 'POST',
  },
  getAllDevices: {
    path: 'api/devices/list',
    getPath: null,
    method: 'GET',
  },
  getDeviceById: {
    path: '',
    getPath: params => `api/devices/get/${params.serial}`,
    method: 'GET',
  },
  updateDevice: {
    path: 'api/devices/update',
    getPath: null,
    method: 'POST',
  },
  deleteDevice: {
    path: '',
    getPath: params => `api/devices/remove/${params.serial}`,
    method: 'GET',
  },
  anonymousGetProfile: {
    path: 'api/user/anonymousProfile',
    getPath: null,
    method: 'GET',
  },
  anonymousAddDevice: {
    path: 'api/anonymous/add',
    getPath: null,
    method: 'POST',
  },
  anonymousGetAllDevices: {
    path: 'api/anonymous/list',
    getPath: null,
    method: 'GET',
  },
  anonymousGetDeviceById: {
    path: '',
    getPath: params => `api/anonymous/get/${params.serial}`,
    method: 'GET',
  },
  anonymousUpdateDevice: {
    path: 'api/anonymous/update',
    getPath: null,
    method: 'POST',
  },
  anonymousDeleteDevice: {
    path: '',
    getPath: params => `api/anonymous/remove/${params.serial}`,
    method: 'GET',
  },
  getDeviceChart: {
    path: '',
    getPath: params => `api/charts/${params.serial}`,
    method: 'POST',
  },
  getTariffInfos: {
    path: 'api/tariff',
    getPath: null,
    method: 'GET',
  },
  getTicket: {
    path: 'api/export/get_ticket',
    getPath: null,
    method: 'POST',
  },
  getStatus: {
    path: 'api/export/get_status',
    getPath: null,
    method: 'POST',
  },
  getAllDeviceGroups: {
    path: 'api/devicegroups',
    getPath: null,
    method: 'GET',
  },
  addDeviceGroup: {
    path: 'api/devicegroups',
    getPath: null,
    method: 'POST',
  },
  removeDeviceGroup: {
    path: '',
    getPath: params => `api/devicegroups/remove/${params.groupId}`,
    method: 'GET',
  },
  renameDeviceGroup: {
    path: '',
    getPath: params => `api/devicegroups/${params.groupId}`,
    method: 'PATCH',
  },
  getDeviceGroupById: {
    path: '',
    getPath: params => `api/devicegroups/${params.groupId}`,
    method: 'GET',
  },
  moveDeviceToGroup: {
    path: '',
    getPath: params => `api/devicegroups/devices/${params.deviceId}/move`,
    method: 'POST',
  },
  addDeviceToGroup: {
    path: '',
    getPath: params => `api/devicegroups/${params.deviceGroupId}/devices`,
    method: 'POST',
  },
  removeDeviceFromGroup: {
    path: '',
    getPath: params =>
      `api/devicegroups/${params.deviceGroupId}/devices/${params.deviceId}`,
    method: 'GET',
  },
  // Rfids
  addRfid: {
    path: 'api/rfids',
    getPath: null,
    method: 'POST',
  },
  getAllRfids: {
    path: 'api/rfids',
    getPath: null,
    method: 'GET',
  },
  getRfidById: {
    // not implemented in backend
    path: '',
    getPath: params => `api/rfids/get/${params.id}`,
    method: 'GET',
  },
  updateRfid: {
    path: '',
    getPath: params => `api/rfids/${params.rfidId}`,
    method: 'PATCH',
  },
  deleteRfid: {
    path: '',
    getPath: params => `api/rfids/${params.id}`,
    method: 'DELETE',
  },
  getAllRfidGroups: {
    path: 'api/rfidgroups',
    getPath: null,
    method: 'GET',
  },
  addRfidGroup: {
    path: 'api/rfidgroups',
    getPath: null,
    method: 'POST',
  },
  removeRfidGroup: {
    path: '',
    getPath: params => `api/rfidgroups/${params.groupId}`,
    method: 'DELETE',
  },
  renameRfidGroup: {
    path: '',
    getPath: params => `api/rfidgroups/${params.groupId}`,
    method: 'PATCH',
  },
  getRfidGroupById: {
    path: '',
    getPath: params => `api/rfidgroups/${params.groupId}`,
    method: 'GET',
  },
  moveRfidToGroup: {
    path: '',
    getPath: params => `api/rfidgroups/rfids/${params.rfidId}/move`,
    method: 'POST',
  },
  addRfidToGroup: {
    path: '',
    getPath: params => `api/rfidgroups/${params.rfidGroupId}/rfids`,
    method: 'POST',
  },
  removeRfidFromGroup: {
    path: '',
    getPath: params => `api/rfidgroups/${params.rfidGroupId}/${params.rfidId}`,
    method: 'DELETE',
  },
  importAnonymousDevices: {
    path: 'api/devices/importanonymous',
    getPath: null,
    method: 'POST',
  },
  getChangelogs: {
    path: 'api/changelogs',
    getPath: null,
    method: 'GET',
  },
  getEnergyReport: {
    path: '',
    getPath: params =>
      `api/devices/reporting?serials=${params.serials}&from=${params.from}&to=${params.to}&formatting=${params.formatting}`,
    method: 'GET',
  },
  // Organizations
  addOrganization: {
    path: 'api/organizations',
    getPath: null,
    method: 'POST',
  },
  getAllOrganizations: {
    path: 'api/organizations',
    getPath: null,
    method: 'GET',
  },
  updateOrganization: {
    path: '',
    getPath: params => `api/organizations/${params.organizationId}`,
    method: 'PATCH',
  },
  deleteOrganization: {
    path: '',
    getPath: params => `api/organizations/${params.id}`,
    method: 'DELETE',
  },
  // organization overview
  getOrganizationById: {
    path: '',
    getPath: params => `api/organizations/${params.id}`,
    method: 'GET',
  },
  inviteMemberToOrganzation: {
    path: '',
    getPath: params =>
      `/api/organizations/${params.organizationId}/invitations`,
    method: 'POST',
  },
  addDevicesToOrganzation: {
    path: '',
    getPath: params => `/api/organizations/${params.organizationId}/devices`,
    method: 'POST',
  },
  assignMembersToDevice: {
    path: '',
    getPath: params =>
      `api/organizations/${params.organizationId}/devices/${params.deviceId}/users`,
    method: 'POST',
  },
  assignDevicesToMember: {
    path: '',
    getPath: params =>
      `api/organizations/${params.organizationId}/users/${params.userId}/devices`,
    method: 'POST',
  },
  removeMembersFromOrganization: {
    path: '',
    getPath: params => `/api/organizations/${params.organizationId}/users`,
    method: 'DELETE',
  },
  removeDevicesFromOrganization: {
    path: '',
    getPath: params => `/api/organizations/${params.organizationId}/devices`,
    method: 'DELETE',
  },
  deviceMemberAccess: {
    path: '',
    getPath: params =>
      `api/organizations/${params.organizationId}/device-user-access`,
    method: 'POST',
  },
};

export interface ApiPointsFetchParamsTypes
  extends Record<never, ApiPointsFetchParamsType> {
  login: {
    body: AuthRequestBody;
    queryParams: undefined;
    response: UserManagementApiSuccessTypes | UserManagementApiErrorTypes;
    urlParams: undefined;
  };
  loginAnonymous: {
    body: undefined;
    queryParams: undefined;
    response: UserManagementApiSuccessTypes | UserManagementApiErrorTypes;
    urlParams: undefined;
  };
  register: {
    body: AuthRequestBody;
    queryParams: undefined;
    response: UserManagementApiSuccessTypes | UserManagementApiErrorTypes;
    urlParams: undefined;
  };
  logout: {
    body: undefined;
    queryParams: undefined;
    response: UserManagementApiSuccessTypes | UserManagementApiErrorTypes;
    urlParams: undefined;
  };
  getProfile: {
    body: undefined;
    queryParams: undefined;
    response: GetProfileResponseTypes;
    urlParams: undefined;
  };
  // setup devices
  setupDevices: {
    body: SetupDevicesRequestBody;
    queryParams: undefined;
    response: SetupDevicesApiResponse;
    urlParams: undefined;
  };
  // group config
  groupConfigDevices: {
    body: GroupConfigDevicesRequestBody;
    queryParams: undefined;
    response: GroupConfigDevicesApiResponse;
    urlParams: undefined;
  };
  verifyDevice: {
    body: AddDeviceRequestBody;
    queryParams: undefined;
    response: VerifyDeviceResponseType;
    urlParams: undefined;
  };
  addDevice: {
    body: AddDeviceRequestBody;
    queryParams: undefined;
    response: AddDeviceResponseType;
    urlParams: undefined;
  };
  getAllDevices: {
    body: undefined;
    queryParams: undefined;
    response: AllDevicesResponseType;
    urlParams: undefined;
  };
  getDeviceById: {
    body: undefined;
    queryParams: undefined;
    response: GetDeviceByIdResponseType;
    urlParams: {
      serial: string;
    };
  };
  updateDevice: {
    body: UpdateDeviceRequestBody;
    queryParams: undefined;
    response: UpdateDeviceResponseType;
    urlParams: undefined;
  };
  deleteDevice: {
    body: undefined;
    queryParams: undefined;
    response: DeleteDeviceResponseType;
    urlParams: {
      serial: string;
    };
  };
  anonymousGetProfile: {
    body: undefined;
    queryParams: undefined;
    response: GetProfileResponseTypes;
    urlParams: undefined;
  };
  anonymousAddDevice: {
    body: AddDeviceRequestBody;
    queryParams: undefined;
    response: AnonymousAddDeviceResponseType;
    urlParams: undefined;
  };
  anonymousGetAllDevices: {
    body: undefined;
    queryParams: undefined;
    response: AllDevicesResponseType;
    urlParams: undefined;
  };
  anonymousGetDeviceById: {
    body: undefined;
    queryParams: undefined;
    response: GetDeviceByIdResponseType;
    urlParams: {
      serial: string;
    };
  };
  anonymousUpdateDevice: {
    body: UpdateDeviceRequestBody;
    queryParams: undefined;
    response: UpdateDeviceResponseType;
    urlParams: undefined;
  };
  anonymousDeleteDevice: {
    body: undefined;
    queryParams: undefined;
    response: DeleteDeviceResponseType;
    urlParams: {
      serial: string;
    };
  };
  getDeviceChart: {
    body: GetDeviceChartsRequestBody;
    queryParams: undefined;
    response: GetDeviceChartsResponseType | ApiErrorType;
    urlParams: {
      serial: string;
    };
  };
  getTariffInfos: {
    body: undefined;
    queryParams: undefined;
    response: ApiSuccessType<{ data: TariffAPIResponse[] }> | ApiErrorType;
    urlParams: undefined;
  };
  getTicket: {
    body: {
      e: string;
    };
    queryParams: undefined;
    response: ExportGetTicketResponseType | ApiErrorType;
    urlParams: undefined;
  };
  getStatus: {
    body: {
      ticket: string;
    };
    queryParams: undefined;
    response: ExportGetStatusResponseType | ApiErrorType;
    urlParams: undefined;
  };
  getAllDeviceGroups: {
    body: undefined;
    queryParams: undefined;
    response: AllDeviceGroupsResponseType;
    urlParams: undefined;
  };
  addDeviceGroup: {
    body: AddDeviceGroupRequestType;
    queryParams: undefined;
    response: AddDeviceGroupResponseType;
    urlParams: undefined;
  };
  removeDeviceGroup: {
    body: undefined;
    queryParams: undefined;
    response: DeleteDeviceGroupResponseType;
    urlParams: {
      groupId: number;
    };
  };
  renameDeviceGroup: {
    body: RenameDeviceGroupRequestType;
    queryParams: undefined;
    response: RenameDeviceGroupResponseType;
    urlParams: {
      groupId: number;
    };
  };
  getDeviceGroupById: {
    body: undefined;
    queryParams: undefined;
    response: GetDeviceGroupByIdResponseType;
    urlParams: {
      groupId: number;
    };
  };
  moveDeviceToGroup: {
    body: MoveDeviceToGroupRequestType;
    queryParams: undefined;
    response: MoveDeviceToGroupResponseType;
    urlParams: {
      deviceId: number;
    };
  };
  addDeviceToGroup: {
    body: AddDeviceToGroupRequestType;
    queryParams: undefined;
    response: AddDeviceToGroupResponseType;
    urlParams: {
      deviceGroupId: number;
    };
  };
  removeDeviceFromGroup: {
    body: undefined;
    queryParams: undefined;
    response: RemoveDeviceFromGroupResponseType;
    urlParams: {
      deviceGroupId: number;
      deviceId: number;
    };
  };
  // // Rfid
  addRfid: {
    body: AddRfidRequestBody;
    queryParams: undefined;
    response: AddRfidResponseType;
    urlParams: undefined;
  };
  getAllRfids: {
    body: undefined;
    queryParams: undefined;
    response: AllRfidsResponseType;
    urlParams: undefined;
  };
  getRfidById: {
    body: undefined;
    queryParams: undefined;
    response: GetRfidByIdResponseType;
    urlParams: {
      id: number;
    };
  };
  updateRfid: {
    queryParams: undefined;
    response: UpdateRfidResponseType;
    body: UpdateRfidRequestBody;
    urlParams: {
      rfidId: number;
    };
  };
  deleteRfid: {
    body: undefined;
    queryParams: undefined;
    response: DeleteRfidResponseType;
    urlParams: {
      id: number;
    };
  };
  getAllRfidGroups: {
    body: undefined;
    queryParams: undefined;
    response: AllRfidGroupsResponseType;
    urlParams: undefined;
  };
  addRfidGroup: {
    body: AddRfidGroupRequestType;
    queryParams: undefined;
    response: AddRfidGroupResponseType;
    urlParams: undefined;
  };
  removeRfidGroup: {
    body: undefined;
    queryParams: undefined;
    response: DeleteRfidGroupResponseType;
    urlParams: {
      groupId: number;
    };
  };
  renameRfidGroup: {
    body: RenameRfidGroupRequestType;
    queryParams: undefined;
    response: RenameRfidGroupResponseType;
    urlParams: {
      groupId: number;
    };
  };
  getRfidGroupById: {
    body: undefined;
    queryParams: undefined;
    response: GetRfidGroupByIdResponseType;
    urlParams: {
      groupId: number;
    };
  };
  addRfidToGroup: {
    body: AddRfidToGroupRequestType;
    queryParams: undefined;
    response: AddRfidToGroupResponseType;
    urlParams: {
      rfidGroupId: number;
    };
  };
  moveRfidToGroup: {
    body: MoveRfidToGroupRequestType;
    queryParams: undefined;
    response: MoveRfidToGroupResponseType;
    urlParams: {
      rfidId: number;
    };
  };
  removeRfidFromGroup: {
    body: undefined;
    queryParams: undefined;
    response: RemoveRfidFromGroupResponseType;
    urlParams: {
      rfidGroupId: number;
      rfidId: number;
    };
  };
  importAnonymousDevices: {
    body: ImportAnonymousDevicesRequestType;
    queryParams: undefined;
    response: ApiSuccessType<undefined> | ApiErrorType;
    urlParams: undefined;
  };
  // changelogs
  getChangelogs: {
    body: undefined;
    queryParams: GetChangelogDataQueryParams;
    response: GetChangelogDataResponse;
    urlParams: undefined;
  };
  getEnergyReport: {
    body: undefined;
    queryParams: undefined;
    response: EnergyReportResponseType;
    urlParams: EnergyReportRequestDataParams;
  };
  // // Organization
  addOrganization: {
    body: AddOrganizationRequestBody;
    queryParams: undefined;
    response: AddOrganizationResponseType;
    urlParams: undefined;
  };
  getAllOrganizations: {
    body: undefined;
    queryParams: undefined;
    response: AllOrganizationsResponseType;
    urlParams: undefined;
  };
  getOrganizationById: {
    body: undefined;
    queryParams: undefined;
    response: GetOrganizationByIdResponseType;
    urlParams: {
      id: number;
    };
  };
  updateOrganization: {
    queryParams: undefined;
    body: UpdateOrganizationRequestType;
    response: UpdateOrganizationResponseType;
    urlParams: {
      organizationId: number;
    };
  };
  deleteOrganization: {
    body: undefined;
    queryParams: undefined;
    response: DeleteOrganizationResponseType;
    urlParams: {
      id: number;
    };
  };
  inviteMemberToOrganzation: {
    body: InviteMemberToOrganizationRequestBody;
    queryParams: undefined;
    response: GetMemberByOrganizationIdResponseType;
    urlParams: {
      organizationId: number;
    };
  };
  addDevicesToOrganzation: {
    body: AddDeviceToOrganizationRequestBody;
    queryParams: undefined;
    response: GetDevicesByOrganizationIdResponseType;
    urlParams: {
      organizationId: number;
    };
  };
  assignMembersToDevice: {
    body: AssignMembersToDeviceRequestBody;
    queryParams: undefined;
    response: AssignMembersToDeviceResponseType;
    urlParams: {
      organizationId: number;
      deviceId: number;
    };
  };
  assignDevicesToMember: {
    body: AssignDevicesToMemberRequestBody;
    queryParams: undefined;
    response: AssignDevicesToMemberResponseType;
    urlParams: {
      organizationId: number;
      userId: number;
    };
  };
  removeMembersFromOrganization: {
    body: RemoveMemberFromOrgRequestBody;
    queryParams: undefined;
    response: RemoveMemberFromOrgResponseType;
    urlParams: {
      organizationId: number;
    };
  };
  removeDevicesFromOrganization: {
    body: RemoveDeviceFromOrgRequestBody;
    queryParams: undefined;
    response: RemoveDeviceFromOrgResponseType;
    urlParams: {
      organizationId: number;
    };
  };
  deviceMemberAccess: {
    body: DeviceAssignmentsRequestBody | MemberAssignmentsRequestBody;
    queryParams: undefined;
    response: DeviceMemberAccessResponseType;
    urlParams: {
      organizationId: number;
    };
  };
}

export type AvailableApiPoints = keyof typeof apiPoints;

export default apiPoints;
