import HelpIcon from '@mui/icons-material/HelpOutlineRounded';
import LogoutIcon from '@mui/icons-material/LogoutRounded';
import TuneIcon from '@mui/icons-material/TuneRounded';
import { List, useMediaQuery, useTheme } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import useIsAnonymousUser from 'hooks/useIsAnonymousUser';
import useProfile from 'hooks/useProfile';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import type { FC } from 'react';

import pageUrls from 'constants/pageUrls';

import AvatarFromDesign from 'components/AvatarFromDesign';
import DashboardIcon from 'components/Icons/DashboardIcon';
import DeviceGroupsIcon from 'components/Icons/DeviceGroupsIcon';
import DeviceLocationsIcon from 'components/Icons/DeviceLocationsIcon';
import DevicesIcon from 'components/Icons/DevicesIcon';
import InstallerIcon from 'components/Icons/InstallerIcon';
import OrganizationsIcon from 'components/Icons/OrganizationsIcon/intex';
import RfidGroupsIcon from 'components/Icons/RfidGroupsIcon';
import RfidLocationsIcon from 'components/Icons/RfidLocationsIcon';
import RfidsIcon from 'components/Icons/RfidsIcon';

import { useAppDispatch } from 'redux-store';
import { closeSidebar } from 'redux-store/slices/toggleSidebarMenu';
import { setPreferencesModal, setProfileModal } from 'redux-store/slices/ui';
import logoutThunk from 'redux-store/thunks/profile/logoutThunk';

import NavLink from './NavLink';
import SidebarGroup from './SidebarGroup';

interface SidebarItemsProps {
  isSidebarExpanded: boolean;
}

// eslint-disable-next-line complexity
const SidebarItems: FC<SidebarItemsProps> = ({ isSidebarExpanded }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isAnonymous = useIsAnonymousUser();
  const { t } = useTranslation();
  const profile = useProfile();
  const theme = useTheme();

  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const handleOpenPreferences = useCallback(() => {
    dispatch(setPreferencesModal(true));
  }, [dispatch]);

  const handleOpenProfile = useCallback(() => {
    dispatch(setProfileModal(true));
  }, [dispatch]);

  const handleLogout = useCallback(async (): Promise<void> => {
    await dispatch(logoutThunk());

    if (!lgUp && isSidebarExpanded) {
      dispatch(closeSidebar());
    }
    Sentry.setUser(null);

    await router.push(pageUrls.login, undefined, {
      locale: router.locale,
    });
  }, [dispatch, lgUp, router, isSidebarExpanded]);

  return (
    <List
      sx={{
        pt: 0,
        pb: 3,
        pl: 1,
        pr: 1,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
      className="sidebarNav"
      component="div"
    >
      <SidebarGroup>
        <NavLink
          isActive={router.pathname === pageUrls.homeDashboard}
          linkProps={{
            href: pageUrls.homeDashboard,
            'data-testid': 'dashboard-button',
          }}
          listItemTextProps={{
            primary: t('common:dashboard'),
          }}
          renderIcon={<DashboardIcon />}
          collapsed={!isSidebarExpanded}
        />
      </SidebarGroup>
      <SidebarGroup>
        <NavLink
          isActive={
            router.pathname === pageUrls.devices.all ||
            router.pathname === pageUrls.devices.specificDeviceTypeGeneric ||
            router.pathname === pageUrls.device.devicePageGeneric ||
            router.pathname.startsWith(pageUrls.settingsPageGeneric)
          }
          linkProps={{
            href: pageUrls.devices.all,
            'data-testid': 'all-devices-button',
          }}
          listItemTextProps={{
            primary: t('common:pages.devices.page_title'),
          }}
          renderIcon={<DevicesIcon />}
          collapsed={!isSidebarExpanded}
          data-testid="all-devices"
        />
        <NavLink
          isActive={
            router.pathname === pageUrls.deviceGroups.all ||
            router.pathname.startsWith(
              pageUrls.deviceGroup.deviceGroupPageGeneric,
            )
          }
          linkProps={{
            href: pageUrls.deviceGroups.all,
            'data-testid': 'device-groups-button',
          }}
          listItemTextProps={{
            primary: t('common:pages.device_groups.page_title'),
          }}
          renderIcon={<DeviceGroupsIcon />}
          collapsed={!isSidebarExpanded}
          hidden={isAnonymous}
        />
        {process.env.HIDE_UNFINISHED_FEATURES === 'true' ? (
          <NavLink
            isActive={
              router.pathname === pageUrls.deviceLocations.all ||
              router.pathname.startsWith(
                pageUrls.deviceLocation.deviceLocationPageGeneric,
              )
            }
            linkProps={{
              href: pageUrls.deviceLocations.all,
              'data-testid': 'device-locations-button',
            }}
            listItemTextProps={{
              primary: t('common:pages.device_locations.page_title'),
            }}
            renderIcon={<DeviceLocationsIcon />}
            collapsed={!isSidebarExpanded}
            hidden={isAnonymous}
          />
        ) : null}
      </SidebarGroup>
      <SidebarGroup hidden={isAnonymous}>
        <NavLink
          isActive={
            router.pathname === pageUrls.rfids.all ||
            router.pathname.startsWith(pageUrls.rfid.rfidPageGeneric)
          }
          linkProps={{
            href: pageUrls.rfids.all,
            'data-testid': 'rfids-button',
          }}
          listItemTextProps={{
            primary: t('common:pages.rfids.page_title'),
          }}
          renderIcon={<RfidsIcon />}
          collapsed={!isSidebarExpanded}
        />
        <NavLink
          isActive={
            router.pathname === pageUrls.rfidGroups.all ||
            router.pathname.startsWith(pageUrls.rfidGroup.rfidGroupPageGeneric)
          }
          linkProps={{
            href: pageUrls.rfidGroups.all,
            'data-testid': 'rfid-groups-button',
          }}
          listItemTextProps={{
            primary: t('common:pages.rfid_groups.page_title'),
          }}
          renderIcon={<RfidGroupsIcon />}
          collapsed={!isSidebarExpanded}
        />
        {process.env.HIDE_UNFINISHED_FEATURES === 'true' ? (
          <NavLink
            isActive={
              router.pathname === pageUrls.rfidLocations.all ||
              router.pathname.startsWith(
                pageUrls.rfidLocation.rfidLocationPageGeneric,
              )
            }
            linkProps={{
              href: pageUrls.rfidLocations.all,
              'data-testid': 'rfid-locations-button',
            }}
            listItemTextProps={{
              primary: t('common:pages.rfid_locations.page_title'),
            }}
            renderIcon={<RfidLocationsIcon />}
            collapsed={!isSidebarExpanded}
          />
        ) : null}
      </SidebarGroup>
      {!isAnonymous && profile?.roles.global.includes('admin') ? (
        <SidebarGroup>
          <NavLink
            isActive={router.pathname === pageUrls.admin.organizations}
            linkProps={{
              href: pageUrls.admin.organizations,
              'data-testid': 'organizations-button',
            }}
            listItemTextProps={{
              primary: 'Organizations',
            }}
            renderIcon={<OrganizationsIcon />}
            collapsed={!isSidebarExpanded}
          />
        </SidebarGroup>
      ) : null}
      {process.env.HIDE_UNFINISHED_FEATURES === 'true' ? (
        <SidebarGroup hidden={isAnonymous}>
          <NavLink
            isActive={router.pathname === pageUrls.installerCodes}
            linkProps={{
              href: pageUrls.installerCodes,
              'data-testid': 'installer-codes-button',
            }}
            listItemTextProps={{
              primary: t('common:pages.installer_codes.page_title'),
            }}
            renderIcon={<InstallerIcon />}
            collapsed={!isSidebarExpanded}
          />
        </SidebarGroup>
      ) : null}
      <SidebarGroup alignment="bottom">
        <NavLink
          listItemTextProps={{
            primary: profile?.displayName ?? profile?.email,
            secondary: profile?.displayName ? profile.email : undefined,
            primaryTypographyProps: {
              sx: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            },
            secondaryTypographyProps: {
              sx: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            },
            title: profile?.displayName || profile?.email || undefined,
          }}
          listItemButtonProps={{
            onClick: () => handleOpenProfile(),
            'data-testid': 'profile-email-button',
          }}
          listItemIconProps={{
            sx: {
              width: 32,
              height: 32,
            },
          }}
          renderIcon={<AvatarFromDesign sx={{ width: 32, height: 32 }} />}
          collapsed={!isSidebarExpanded}
          hidden={isAnonymous}
        />
        <NavLink
          listItemTextProps={{
            primary: t('common:preferences'),
          }}
          listItemButtonProps={{
            onClick: () => handleOpenPreferences(),
            'data-testid': 'preferences-button',
          }}
          listItemIconProps={{
            sx: {
              width: 32,
              height: 32,
            },
          }}
          renderIcon={<TuneIcon />}
          collapsed={!isSidebarExpanded}
        />
        {process.env.HIDE_UNFINISHED_FEATURES === 'true' ? (
          <NavLink
            linkProps={{
              href: pageUrls.help,
              'data-testid': 'help-and-support-link',
            }}
            listItemTextProps={{
              primary: t('common:help_and_support'),
            }}
            listItemIconProps={{
              sx: {
                width: 32,
                height: 32,
              },
            }}
            renderIcon={<HelpIcon width={24} height={24} />}
            collapsed={!isSidebarExpanded}
          />
        ) : null}
        <NavLink
          listItemTextProps={{
            primary: t('common:logout'),
          }}
          listItemButtonProps={{
            onClick: handleLogout,
            'data-testid': 'logout-button',
          }}
          listItemIconProps={{
            sx: {
              width: 32,
              height: 32,
            },
          }}
          renderIcon={<LogoutIcon />}
          collapsed={!isSidebarExpanded}
        />
      </SidebarGroup>
    </List>
  );
};

export default SidebarItems;
