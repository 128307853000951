import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Divider,
  IconButton,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import type { AddDeviceGroupModalStageProps } from 'content/modals/AddDeviceGroupModal';
import Fuse from 'fuse.js';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useDebounce } from 'use-debounce';

import pageUrls from 'constants/pageUrls';

import Button from 'components/Button';
import ErrorText from 'components/ErrorText';
import AllDevicesOutlinedIcon from 'components/Icons/AllDevicesOutlinedIcon';
import CancelIcon from 'components/Icons/CancelIcon';
import FilledTextField from 'components/MuiCustom/FilledTextField';

import { useAppDispatch, useAppSelector } from 'redux-store';
import type { AddDeviceToGroupResponseType } from 'redux-store/slices/deviceGroups/types';
import type { DeviceSerial } from 'redux-store/slices/ui/types';
import addDeviceGroupThunk from 'redux-store/thunks/deviceGroups/addDeviceGroupThunk';
import addDeviceToDeviceGroupThunk from 'redux-store/thunks/deviceGroups/addDeviceToDeviceGroupThunk';
import getAllDeviceGroupsThunk from 'redux-store/thunks/deviceGroups/getAllDeviceGroupsThunk';

import DeviceGroupsDeviceItem from './DeviceGroupsDeviceItem';

const AddDeviceGroupSetDevicesContent: FC<AddDeviceGroupModalStageProps> = ({
  state,
  groupID,
  setState,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const router = useRouter();

  const selectedDeviceSerials = useMemo(() => state.devices, [state.devices]);
  const setSelectedDeviceSerials = useCallback(
    (devices: DeviceSerial[]) => {
      setState(prev => ({ ...prev, devices }));
    },
    [setState],
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [searchString, setSearchString] = useState('');
  const [searchResults, setSearchResults] = useState<string[] | null>(null);

  const devicesWithNames = useAppSelector(
    appState =>
      appState.devices.items.map(device => ({
        ...device,
        name: appState.api.fullStatus[device.serial]?.fna,
      })),
    (a, b) => a.join() === b.join(),
  );

  const fuse = useMemo(
    () =>
      new Fuse(devicesWithNames, {
        keys: ['name', 'serial'],
        threshold: 0.4,
      }),
    [devicesWithNames],
  );

  const handleSearch = useCallback((): void => {
    if (!searchString) {
      setSearchResults(null);

      return;
    }

    const results = fuse.search(searchString);

    setSearchResults(results.map(result => result.item.serial));
  }, [fuse, searchString]);

  const [debouncedSearch] = useDebounce(searchString, 500);

  useEffect(() => {
    if (debouncedSearch) {
      handleSearch();
    }
  }, [debouncedSearch, handleSearch]);

  const handleClearSearch = useCallback((): void => {
    setSearchString('');
    setSearchResults(null);
  }, []);

  const filteredDevices = useMemo(
    () =>
      searchResults
        ? devicesWithNames.filter(device =>
            searchResults.includes(device.serial),
          )
        : devicesWithNames,
    [devicesWithNames, searchResults],
  );

  const filteredSelectedDeviceSerials = useMemo(
    () =>
      selectedDeviceSerials.filter(serial =>
        filteredDevices.some(device => device.serial === serial),
      ),
    [filteredDevices, selectedDeviceSerials],
  );

  const handleCreateGroup = useCallback(
    async (skipDevices: boolean) => {
      let result = undefined;

      const filteredDeviceIds = filteredDevices
        .filter(device => filteredSelectedDeviceSerials.includes(device.serial))
        .map(device => device.id);

      try {
        setLoading(true);

        result = await dispatch(
          addDeviceGroupThunk({
            deviceGroup: {
              name: state.name,
              devices: skipDevices ? [] : filteredDeviceIds,
            },
          }),
        ).unwrap();

        if (!result.success) {
          setError(
            t(
              'common:pages.device_groups.add_device_group.errors.error_creating_group_with_error',
              { error: result.error },
            ),
          );
          setLoading(false);

          return;
        }

        setError(null);

        setState({ name: '', devices: [], stage: 'success' });

        await dispatch(getAllDeviceGroupsThunk({})).unwrap();

        setTimeout(() => {
          setState({ name: '', devices: [], stage: 'name' });
          onClose();
        }, 1250);
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            filteredDeviceIds: filteredDeviceIds,
            filteredSelectedDeviceSerials,
            selectedDeviceSerials,
            state,
            result,
          },
        });
      } finally {
        setLoading(false);
      }
    },
    [
      dispatch,
      filteredDevices,
      filteredSelectedDeviceSerials,
      onClose,
      selectedDeviceSerials,
      setState,
      state,
      t,
    ],
  );

  const handleAddDevicesToGroup = useCallback(
    async (deviceGroupId: number) => {
      let results: AddDeviceToGroupResponseType[] = [];
      const errors: { serial: DeviceSerial; err: string }[] = [];

      const filteredDevicesList = filteredDevices.filter(device =>
        filteredSelectedDeviceSerials.includes(device.serial),
      );

      try {
        setLoading(true);

        results = await Promise.all(
          filteredDevicesList.map(
            async (device): Promise<AddDeviceToGroupResponseType> => {
              try {
                const response = await dispatch(
                  addDeviceToDeviceGroupThunk({
                    deviceGroupId,
                    data: { deviceId: device.id },
                  }),
                ).unwrap();

                if (!response.success) {
                  errors.push({ serial: device.serial, err: response.error });
                }

                return response;
              } catch (err) {
                const errorMessage =
                  err instanceof Error ? err.message : String(err);

                errors.push({ serial: device.serial, err: errorMessage });

                return { success: false, error: errorMessage };
              }
            },
          ),
        );

        // If any device failed, show errors
        if (errors.length > 0) {
          setError(
            t(
              'common:pages.device_groups.add_device_group.errors.error_adding_devices_to_group',
              {
                error: errors
                  .map(({ serial, err }) => `${serial}: ${err}`)
                  .join(', '),
              },
            ),
          );

          return;
        }

        setError(null);
        setState({ name: '', devices: [], stage: 'success' });

        await router.replace(pageUrls.deviceGroup.all(deviceGroupId));

        setTimeout(() => {
          setState({ name: '', devices: [], stage: 'name' });
          onClose();
        }, 1250);
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            selectedDeviceSerials,
            state,
            results,
            errors,
          },
        });
      } finally {
        setLoading(false);
      }
    },
    [
      dispatch,
      filteredDevices,
      filteredSelectedDeviceSerials,
      onClose,
      router,
      selectedDeviceSerials,
      setState,
      state,
      t,
    ],
  );

  return (
    <Box>
      <Box
        sx={{
          height: '55vh',
          overflow: 'auto',
          paddingBottom: 5,
        }}
      >
        <Box mb={4} width="100%">
          <Typography
            variant="h2"
            textAlign="center"
            mb={2}
            data-testid="create-group-modal-title"
          >
            {t('common:pages.device_groups.add_device_group.add_to_group')}
          </Typography>
          <Typography variant="body1" textAlign="center">
            {t(
              'common:pages.device_groups.add_device_group.select_devices_description',
            )}
          </Typography>
        </Box>
        <Box mb={4} width="100%">
          <ListItemButton
            sx={{
              backgroundColor: theme.vars.palette.grey[100],
              borderRadius: 2,
            }}
            onClick={() =>
              setState(prev => ({
                ...prev,
                stage: 'addDevice',
                devices: selectedDeviceSerials,
              }))
            }
          >
            <ListItemText
              color="primary"
              primaryTypographyProps={{
                style: {
                  color: theme.vars.palette.primary.dark,
                  fontWeight: 500,
                },
              }}
              primary={t(
                'common:pages.device_groups.add_device_group.add_new_device',
              )}
            />
            <IconButton edge="end" disableRipple>
              <AddCircleOutlineRoundedIcon color="primary" />
            </IconButton>
          </ListItemButton>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography variant="h3">
            {t('common:pages.device_groups.add_device_group.your_devices')}
          </Typography>
          {selectedDeviceSerials.length > 0 ? (
            <Box display="flex" gap={0.5} alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={16}
                height={16}
                borderRadius="50%"
                bgcolor={theme.vars.palette.primary.main}
                color={theme.vars.palette.common.white}
              >
                <Typography variant="caption" lineHeight={1.5}>
                  {selectedDeviceSerials.length}
                </Typography>
              </Box>
              <AllDevicesOutlinedIcon color="primary" fontSize="medium" />
            </Box>
          ) : null}
        </Box>
        <Box mb={3} width="100%">
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <FilledTextField
              value={searchString}
              onChange={e => setSearchString(e.target.value)}
              fullWidth
              label={t(
                'common:pages.device_groups.add_device_group.search_for_name_or_serial',
              )}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={searchResults ? handleClearSearch : handleSearch}
                  >
                    {searchResults ? <CancelIcon /> : <SearchIcon />}
                  </IconButton>
                ),
              }}
            />
          </form>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          overflow="auto"
          flexGrow={0}
          flexShrink={1}
          flexBasis="auto"
          width="100%"
        >
          {filteredDevices.map(device => (
            <DeviceGroupsDeviceItem
              key={device.serial}
              device={device}
              selectedDeviceSerials={selectedDeviceSerials}
              setSelectedDeviceSerials={setSelectedDeviceSerials}
            />
          ))}
          {filteredDevices.length === 0 ? (
            <Typography variant="body1" textAlign="center">
              {t(
                'common:pages.device_groups.add_device_group.no_devices_found',
              )}
            </Typography>
          ) : null}
        </Box>
      </Box>
      <ErrorText error={error} />
      <Divider />
      <Box width="100%" display="flex" flexDirection="column" gap={2} pt={2}>
        <Button
          variant="flat"
          size="large"
          fullWidth
          onClick={() =>
            groupID
              ? handleAddDevicesToGroup(groupID)
              : handleCreateGroup(false)
          }
          disabled={loading || selectedDeviceSerials.length === 0}
        >
          {t('common:add')}
        </Button>
        {groupID ? null : (
          <Button
            variant="text"
            fullWidth
            onClick={() => handleCreateGroup(true)}
            size="large"
          >
            {t('common:pages.device_groups.add_device_group.skip_add_group')}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AddDeviceGroupSetDevicesContent;
