import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';
import type { FC } from 'react';

interface SideBarIconProps extends SvgIconProps {
  isOpen: boolean;
}

const SideBarIcon: FC<SideBarIconProps> = ({ isOpen, ...props }) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    {isOpen ? (
      <path
        d="M16.5 14.8V9.2C16.5 8.96667 16.4 8.80833 16.2 8.725C16 8.64167 15.8167 8.68333 15.65 8.85L13.2 11.3C13 11.5 12.9 11.7333 12.9 12C12.9 12.2667 13 12.5 13.2 12.7L15.65 15.15C15.8167 15.3167 16 15.3583 16.2 15.275C16.4 15.1917 16.5 15.0333 16.5 14.8ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM8 19V5H5V19H8ZM10 19H19V5H10V19Z"
        fill={props.fill}
      />
    ) : (
      <path
        d="M12.5 9.2V14.8C12.5 15.0333 12.6 15.1917 12.8 15.275C13 15.3583 13.1833 15.3167 13.35 15.15L15.8 12.7C16 12.5 16.1 12.2667 16.1 12C16.1 11.7333 16 11.5 15.8 11.3L13.35 8.85C13.1833 8.68333 13 8.64167 12.8 8.725C12.6 8.80833 12.5 8.96667 12.5 9.2ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM8 19V5H5V19H8ZM10 19H19V5H10V19Z"
        fill={props.fill}
      />
    )}
  </SvgIcon>
);

export default SideBarIcon;
