import {
  Box,
  useMediaQuery,
  Drawer,
  IconButton,
  useTheme,
} from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import type { FC, ReactElement } from 'react';
import { useDispatch } from 'react-redux';

import files from 'constants/files';

import SideBarIcon from 'components/Icons/SideBarIcon';

import { useAppSelector } from 'redux-store';
import {
  closeSidebar,
  toggleSidebar,
} from 'redux-store/slices/toggleSidebarMenu';

import SidebarItems from './SidebarItems';

const sidebarCollapsedWidth = 72;
const sidebarExpandedWidth = 300;

const Sidebar: FC = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const theme = useTheme();
  const isSidebarOpen = useAppSelector(state => state.sidebar.isOpen);

  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const showExpandedContent = isSidebarOpen || !lgUp;

  const handleToggleSidebar = (): void => {
    dispatch(toggleSidebar());
  };

  const [isResolved, setIsResolved] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (lgUp !== undefined) {
      setIsResolved(true);
    }
  }, [lgUp]);

  useEffect(() => {
    if (isResolved && !lgUp) {
      dispatch(closeSidebar());
    }
  }, [dispatch, isResolved, lgUp, router.pathname]);

  const SidebarContent = (): ReactElement => (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      pl={isSidebarOpen ? 2 : 0}
      pr={isSidebarOpen ? 2 : 0}
    >
      <Box
        display="flex"
        sx={{ padding: '0 10px' }}
        justifyContent="flex-start"
        alignItems="center"
        height={80}
        position="relative"
        mt={2.5}
      >
        <IconButton
          onClick={handleToggleSidebar}
          sx={{
            borderRadius: 2,
            height: 48,
            width: 56,
            mt: !lgUp ? -5 : -2,
            ml: -0.2,
          }}
        >
          <SideBarIcon sx={{ color: 'grey.500' }} isOpen={isSidebarOpen} />
        </IconButton>
        <Box ml={5}>
          {lgUp && showExpandedContent ? (
            <Image
              src={files.logo.normal}
              alt="go-e Logo"
              width={65}
              height={32}
              priority
            />
          ) : null}
        </Box>
      </Box>
      <SidebarItems isSidebarExpanded={isSidebarOpen} />
    </Box>
  );

  if (isResolved && lgUp) {
    return (
      <Box
        sx={{
          width: isSidebarOpen ? sidebarExpandedWidth : sidebarCollapsedWidth,
          flexShrink: 0,
        }}
      >
        <Drawer
          anchor="left"
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: isSidebarOpen
                ? sidebarExpandedWidth
                : sidebarCollapsedWidth,
              transition: 'width 0.3s ease',
              boxSizing: 'border-box',
              backgroundColor: theme.vars.palette.grey[100],
              borderRight: 0,
            },
          }}
        >
          <SidebarContent />
        </Drawer>
      </Box>
    );
  }

  if (isSidebarOpen) {
    return (
      <Drawer
        anchor="left"
        open={isSidebarOpen}
        onClose={handleToggleSidebar}
        variant="temporary"
        PaperProps={{
          sx: {
            width: '95%',
            maxWidth: sidebarExpandedWidth,
            boxShadow: theme.shadows[8],
          },
        }}
      >
        <SidebarContent />
      </Drawer>
    );
  }
};

export default Sidebar;
