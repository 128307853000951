import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputBase, styled } from '@mui/material';
import { useTranslation } from 'next-i18next';
import type { FC } from 'react';

interface CustomSearchBarProps {
  hasSearched?: boolean;
  search?: string;
  setSearch: (value: string) => void;
  handleSearch?: () => void;
  handleClearSearch: () => void;
  customWidth?: number;
}

const SearchbarBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 200,
  width: '100%',
  backgroundColor: `${theme.vars.palette.grey[100]}`,
  borderRadius: 16,
  padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
  '&:hover': {
    backgroundColor: `${theme.vars.palette.grey[200]}`,
  },
}));

const CustomSearchbar: FC<CustomSearchBarProps> = ({
  search,
  setSearch,
  hasSearched,
  handleSearch,
  handleClearSearch,
  customWidth,
}) => {
  const { t } = useTranslation();

  return (
    <Box width="100%">
      <form
        onSubmit={event => {
          event.preventDefault();
          handleSearch?.();
        }}
      >
        <SearchbarBox sx={{ width: customWidth }}>
          <IconButton
            onClick={hasSearched ? handleClearSearch : handleSearch}
            edge="start"
            disableRipple
          >
            {hasSearched ? (
              <ClearIcon sx={{ color: 'grey.500' }} />
            ) : (
              <SearchIcon sx={{ color: 'grey.500' }} />
            )}
          </IconButton>
          <InputBase
            placeholder={t('common:search_with_dots') as string}
            inputProps={{ 'aria-label': 'search' }}
            sx={{ width: '100%', padding: 0.5 }}
            value={search}
            onChange={event => setSearch(event.target.value)}
            data-testid="search-input-block"
          />
        </SearchbarBox>
      </form>
    </Box>
  );
};

export default CustomSearchbar;
